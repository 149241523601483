<template>
  <div class="page3box">
    <div class="recruit1"> 
      <!-- <TopBar></TopBar> -->
      <div>合作伙伴</div>
      <div class="box-wrap"> 
        <div class="box">
            <img src="@/assets/img/pc/about/北控水务.png" style="width: 140px;height:66px;" >
        </div>
        <div class="box">
            <img src="@/assets/img/pc/about/创业环保.png" style="width: 140px;height:66px;"  >
        </div>
        <div class="box">
            <img src="@/assets/img/pc/about/上海城投.png" style="width: 140px;height:66px;"  >
        </div>
        <div class="box">
            <img src="@/assets/img/pc/about/中国水利水电科学研究院.png"  >
        </div>
        <div class="box">
            <img src="@/assets/img/pc/about/上海水利院.png"  >
        </div>
        <div class="box">
            <img src="@/assets/img/pc/about/pic3.png"  >
        </div>
        <div class="box">
            <img src="@/assets/img/pc/about/pic4.png"  >
        </div>
        <div class="box">
            <img src="@/assets/img/pc/about/pic5.png"  >
        </div>
        <div class="box">
            <img src="@/assets/img/pc/about/pic6.png"  >
        </div>
      </div>
    </div>

    <div class="recruit1">
        <div class="">加入我们</div>
        <div class="jobs ">
          <span @click="goDetail(0)">区域销售经理</span>
          <span @click="goDetail(1)">行业销售总监</span>
          <span @click="goDetail(2)">售前咨询</span>
          <span @click="goDetail(3)">产品经理</span>
          <span @click="goDetail(4)">网络实施工程师</span>
          <span @click="goDetail(5)">项目实施工程师</span>
          <span @click="goDetail(6)">项目经理</span>
          <span @click="goDetail(7)">更多</span>
        </div>
    </div>

    <div class="contact_wrapper">
      <div>联系我们</div>
      <div class="contact_detail"> 
        <div class="qrcode_wrap">
          <img src="@/assets/img/logo/qrcode.png" >
          <div class="sub_title">企业公众号</div>
        </div>
        <div class="contact">
          <span class="contact_title">
            <img class="contact_title_img" src="@/assets/img/pc/icon_parent.png" />
            总部
          </span>
            <span>
                地址：北京市东城区东直门外大街46号天恒大厦B座16层
            </span>
            <span>
                邮编：100027
            </span>
            <span>
                邮箱：service@cyberwater.cn
            </span>
            <div>
              备案号：<a href="http://beian.miit.gov.cn" target="new" style="color: #0087FF">京ICP备20010617号</a>
            </div>
        </div>
        <div class="contact">
          <span class="contact_title">
            <img class="contact_title_img" src="@/assets/img/pc/icon_affiliate.png" />
            分支机构
          </span>
            <span>
              深圳：南山区沙河西路南山智谷产业园F栋2
            </span>
            <span>
              成都：天府新区协和下街同森元气港写字楼1
            </span>
            <span>
              西安：高新区科技二路68号清华科技园E座
            </span>
            <div>
              上海：普陀区大渡河路388弄华宏商务中心
            </div>
        </div>
        <div class="qrcode_wrap">
          <img src="@/assets/img/pc/qrcode_sale.png" >
          <div class="sub_title">企业销售</div>
        </div>
      </div>
      
    </div>
    <!-- <div class="page_bottom"></div> -->
  </div>
</template>

<script>

export default {
  methods: {
    skipPage(index){
      this.$bus.$emit('page-slide-to', index);
    },
    goDetail(index){
      this.$router.push({name:"recruit",params:{curId:index}});
    }
  },
};
</script>

<style lang="less" scoped>
.page3box {
  height: 100vh;
  color: #fff;
  padding-right: 5%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  .recruit1 {
    margin-top: 30px;
    color: white;
    font-size: 28px;
    font-family: Microsoft JhengHei;
    font-weight: lighter;
    line-height: 70px;
    .box-wrap{
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: center;
      background:linear-gradient(0deg,rgba(3,17,36,1),rgba(34,59,108,1));
      border-radius:15px;
      
      .box {
        position: relative;
      }
      .box::after{
        position: absolute;
        z-index: 2;
        content: "";
        width: 1px;
        height: 30px;
        top: 50%;
        left: -2px;
        margin-top: -15px;
        background: rgba(85,93,118,1);
      }
      .box:nth-child(1)::after{
        display: none;
      }
    }
    .jobs{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      flex-wrap: nowrap;
      width: 100%;
      line-height: 34px;
      span{
        font-size: 16px;
        margin-right: 6px;
        padding: 0 20px;
        color: #fff;
        line-height: 34px;
        display: inline-block;
        word-wrap:break-word; 
        white-space:normal;
        border: 1px solid white;
        font-weight: lighter;
        cursor: pointer;
      }
    }
  }
  .contact_wrapper{
    display: flex;
    flex-direction: column;
    color: white;
    font-size: 28px;
    font-family: Microsoft JhengHei;
    font-weight: lighter;
    margin-top: 50px;
    .contact_detail {
      margin-top: 20px;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-around;
      .qrcode_wrap {      
        font-size: 12px;
        img{
          width: 120px;
          margin-bottom: 6px;
        }
        .sub_title {
          font-weight: 500;
          color: #FFFFFF;
          letter-spacing: 1px;
          text-align: center;
          font-size: 14px;
        }
      }
      .contact {
        display: flex;
        flex-direction: column;
        font-size: 16px;
        line-height: 24px;
        font-size: 12px;
        font-weight: 400;
        color: #FEFEFE;

        &_title {
          width: 193px;
          height: 24px;
          background: rgba(255,255,255,0.1);
          display: flex;
          align-items: center;
          &_img {
            width: 14px;
            height: 14px;
            margin: 0 8px 0 14px;
          }
        }
        span {
          margin-bottom: 8px;
        }
      }

    }
  }
  .page_bottom {
    width: 100vw;
    height: 240px;
    background: rgba(1, 20, 37, 0.9);
    position: absolute;
    left: 0;
    bottom: 0;
    margin-left: -150px;
  }
}
</style>