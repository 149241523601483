<template>
  <div class="slidecard" :style="imgStyle">
    <img :src="img" :alt="name" class="cardimg" :style="imgStyle" />
    <div class="cardname">{{ name }}</div>
    <ul class="cardlist" ref="list">
      <div class="cardlisttitle">{{ name }}</div>
      <li class="carditem" v-for="(item, index) in list" :key="index">
        {{ item }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    img: String,
    name: String,
    list: Array,
    width: Number,
    height: Number,
    rows: Number,
  },
  computed: {
    imgStyle() {
      const width = this.$props.width || 200;
      let height = this.$props.height || "auto";
      
      if(this.$props.rows === 2) {
        height = `calc(var(--vh) * 33 )`;
      } else if(this.$props.rows === 3) {
        height = `calc(var(--vh) * 27 )`;
      } 

      return {
        width: width + "px",
        height: typeof height !== 'number' ? height : height + "px",
      };
    },
  }
};
</script>

<style lang="less" scoped>
.slidecard {
  position: relative;
  width: 200px;
  .cardimg {
    width: 100%;
    height: auto;
  }
  .cardname {
    position: absolute;
    z-index: 1;
    left: 0;
    bottom: 0;
    right: 0;
    height: 40px;
    background-color: rgba(0, 0, 0, 0.2);
    color: #fff;
    text-align: center;
    line-height: 40px;
    font-size: 18px;
    font-weight: bold;
  }
  .cardlist {
    position: absolute;
    top: 100%;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 2;
    transition: top 0.2s;
    color: #fff;
    &.active {
      top: 0%;
    }

    .cardlisttitle {
      text-align: center;
      padding-top: 10px;
      font-size: 14px;
      font-weight: bold;
      text-shadow: 0px 2px 4px rgb(0 0 0 / 50%);
      letter-spacing: 2px;
      line-height: 22px;
      transition: all 0.8s ease;
    }

    .carditem {
      font-size: 12px;
      line-height: 1.5;
      letter-spacing: 2px;
      font-weight: 400;
      white-space: normal;
      margin-left: 20px;
      margin-bottom: 5px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
</style>
