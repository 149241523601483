<template>
  <div>
    <div
      v-show="visible"
      class="menu-top"
      @click="isShowMenu = !isShowMenu"
    ></div>
    <div class="nav" v-show="isShowMenu">
      <ul>
        <li @click="skipPage(0)" :style="activeIndex === 0 ? 'color: #2CADFF' : ''"><img src="@/assets/img/navMenu/icon_home.png" class="img" />首页</li>
        <li @click="skipPage(1)" :style="activeIndex === 1 ? 'color: #2CADFF' : ''"><img src="@/assets/img/navMenu/icon_solve.png" class="img" />解决方案</li>
        <li @click="skipPage(2)" :style="activeIndex === 2 ? 'color: #2CADFF' : ''"><img src="@/assets/img/navMenu/icon_product.png" class="img" />核心产品</li>
        <li @click="skipPage(3)" :style="activeIndex === 3 ? 'color: #2CADFF' : ''"><img src="@/assets/img/navMenu/icon_case.png" class="img" />成功案例</li>
        <li @click="skipPage(4)" :style="activeIndex === 4 ? 'color: #2CADFF' : ''"><img src="@/assets/img/navMenu/icon_about.png" class="img" />关于我们</li>
        <li @click="skipPage(5)" :style="activeIndex === 5 ? 'color: #2CADFF' : ''"><img src="@/assets/img/navMenu/icon_contact.png" class="img" />联系我们</li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    activeIndex: {
      type: Number,
      default: 0
    },
  },
  data() {
    return {
      visible: false,
      isShowMenu: false,
    };
  },
  created() {
    this.$bus.$on("page-slide-change", (index) => {
      this.isShowMenu = false;
      this.visible = index !== 0;
    });
    this.$bus.$on("page-slide-click", () => {
      this.isShowMenu = false;
    });
  },
  methods: {
    skipPage(index) {
      this.isShowMenu = false;
      this.$bus.$emit("page-slide-to", index);
    },
  },
};
</script>

<style lang="less" scoped>
.menu-top {
  width: 50px;
  height: 50px;
  background: url("@/assets/img/logo/logo-s.png") no-repeat;
  background-size: 30px;
  background-position: center;
  position: fixed;
  z-index: 999;
  top: 0;
  right: 0;
}

.nav {
  position: fixed;
  top: 50px;
  right: 10px;
  z-index: 1;
  width: 45vw;
  background: rgba(0,11,21,0.9);
  box-shadow: 0px 5px 15px 0px rgba(44,173,255,0.25);
  border-radius: 6px;
  font-size: 16px;
  li {
    line-height: 55px;
    list-style: none;
    text-align: center;
    color: white;
    border-bottom: 1px solid rgba(44,173,255,0.25);
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    .img {
      width: 24px;
      height: 24px;
      margin-right: 10px;
    }
    &:last-child{
        border-bottom: none;
    }
    &:active {
      color: #2CADFF;
    }
  }
}
</style>
