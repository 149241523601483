<template>
    <div class="foot">
        <div class="sub_title">
            <img src="@/assets/img/logo/logo-s.png" >
        </div>
        <span @click="skipPage(1)" class="sub_title" :style="curIndex === 1 ? 'color: #2CADFF' : ''">首页</span>
        <span  @click="skipPage(2)" class="sub_title" :style="curIndex === 2 ? 'color: #2CADFF' : ''">解决方案</span>
        <span @click="skipPage(3)" class="sub_title" :style="curIndex === 3 ? 'color: #2CADFF' : ''">核心产品</span>
        <span @click="skipPage(4)" class="sub_title" :style="curIndex === 4 ? 'color: #2CADFF' : ''">成功案例</span>
        <span @click="skipPage(5)" class="sub_title" :style="curIndex === 5 ? 'color: #2CADFF' : ''">关于我们</span> 
        <span @click="skipPage(6)" class="sub_title" :style="curIndex === 6 ? 'color: #2CADFF' : ''">联系我们</span>
        <!-- <span class="sub_title">模型库</span> -->
        <!-- <a href="http://beian.miit.gov.cn"  class="sub_title" target="new">京ICP备20010617号</a> -->
    </div>
</template>

<script>
export default {
    props: {
        curIndex: {
            type: Number,
            default: 0
        }
    },
    methods: {
      skipPage(index){
        this.$bus.$emit('page-slide-to', index);
      },
    }
}
</script>
<style lang="less" scoped>
.foot {
    position: fixed;
    left: calc(50% - 273px);
    top: 20px;
    z-index: 9999;

    display: flex;
    flex-direction: row;
    flex-wrap:wrap;
    align-items: center;
    color: black;
    margin-top: 100px;
    margin-bottom: 50px;
    // flex-grow: 1;
    margin: 0 auto;
    font-size: 16px;
    font-family: Microsoft YaHei;
    .sub_title {           
    margin-right: 22px;
    margin-bottom: 10px;
    color: #fff;
    cursor: pointer;
    &:hover {
        color: #2CADFF;
    }
    img {
        width: 40px;
    }     
    }

    a {
    text-decoration: none;
    }
}
</style>