export class Mark {
    constructor(text, left, top, p1, p2, p3, src, highlightSrc, type, transformOrigin,  isclick, circleNum) {
        this.text = text;
        this.left = left;
        this.top = top;
        this.p1 = p1;
        this.p2 = p2;
        this.p3 = p3;
        this.src = src;
        this.highlightSrc = highlightSrc;
        this.type = type;
        this.transformOrigin = transformOrigin;
        this.el = document.createDocumentFragment('div');
        this.svg = null;
        this.div = null;
        this.imgleft = null;
        this.textdiv = null;
        this.imgright = null;
        this.topline = null;
        this.bottomline = null;
        // 当前节点是否可点击
        this.isclick = isclick;
        // 多个标记点位共用一个位置，需隐藏圆
        this.circleNum = circleNum;
        this.circleSvg = null;
        this.mapbox = document.getElementsByClassName('mapbox')[0];
        this.init();
    }

    mount(parent) {
        this.parent = document.querySelector(parent);
        this.parent.appendChild(this.el);
    }

    init () {
        this.svg = this.createSVG(this.p1, this.p2, this.p3);
        this.el.appendChild(this.svg);
        this.div = document.createElement('div');
        this.div.classList.add('mapitem');
        if(this.isclick === 'clickable') {
            this.div.classList.add('clickable');
            this.div.style.cursor = "pointer";
        }
        this.div.style.left = this.left;
        this.div.style.top = this.top;
        this.div.style.transformOrigin = this.transformOrigin
        this.el.appendChild(this.div);
        // 左侧图片
        const imgleft = this.createImgleft();
        this.div.appendChild(imgleft);
        // 中间文本
        this.textdiv = this.createTextdiv();
        this.div.appendChild(this.textdiv);
        // 右侧三角形
        const imgright = this.createImgright();
        this.div.appendChild(imgright);
        // 上方横线
        const topline = this.createTopline('./img/label_normal_decorate.png');
        this.div.appendChild(topline);
        // 下方横线
        this.bottomline = this.createBottomline('./img/label_normal_decorate.png');
        this.div.appendChild(this.bottomline);
        this.addHover();
    }

    highlight(data) {
        this.imgleft.style.background = `url(${this.highlightSrc}) 50% 50% no-repeat`;
        this.imgright.classList.add(data);
        this.topline.classList.add(data);
        this.bottomline.classList.add(data);
        this.textdiv.classList.add(data);
        if(this.circleNum) {
            this.circleSvg = this.mapbox.querySelectorAll('svg')[this.circleNum];
            this.circleSvg.querySelector('circle').setAttribute('stroke', '#FF8A00');
            this.circleSvg.querySelector('circle').setAttribute('fill', '#FF8A00');
        }else {
            this.svg.querySelector('circle').setAttribute('stroke', '#FF8A00');
            this.svg.querySelector('circle').setAttribute('fill', '#FF8A00');
        }
        this.svg.querySelector('g').setAttribute('stroke', '#FF8A00');
    }

    unhighlight(data) {
        this.imgleft.style.background = `url(${this.src}) 50% 50% no-repeat`;
        this.imgright.classList.remove (data);
        this.topline.classList.remove(data);
        this.bottomline.classList.remove(data);
        this.textdiv.classList.remove(data);
        if(this.circleNum) {
            this.circleSvg = this.mapbox.querySelectorAll('svg')[this.circleNum];
            this.circleSvg.querySelector('circle').setAttribute('stroke', '#00E1FF');
            this.circleSvg.querySelector('circle').setAttribute('fill', '#00E1FF');
        }else {
            this.svg.querySelector('circle').setAttribute('stroke', '#00E1FF');
            this.svg.querySelector('circle').setAttribute('fill', '#00E1FF');
        }
        this.svg.querySelector('g').setAttribute('stroke', '#00E1FF');
    }

    createImgleft() {
        this.imgleft = document.createElement('div');
        this.imgleft.style.width = '36px';
        this.imgleft.style.height = '29px';
        this.imgleft.style.background = `url(${this.src}) 50% 50% no-repeat`
        return this.imgleft;
    }

    createTextdiv() {
        const div = document.createElement('div');
        div.classList.add('text-box');
        div.innerHTML = this.text;
        return div;
    }

    createImgright() {
        this.imgright = document.createElement('div');
        this.imgright.classList.add('imgright')
        return this.imgright;
    }

    createTopline() {
        this.topline = document.createElement('div');
        this.topline.classList.add('top_line');
        return this.topline;
    }

    createBottomline() {
        this.bottomline = document.createElement('div');
        this.bottomline.classList.add('bottom_line');
        return this.bottomline;
    }

    createSVG(p1, p2, p3) {
        let svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
        svg.setAttribute('width', '100%');
        svg.setAttribute('height', '100%');
        svg.setAttribute('version', '1.1');
        if(this.circleNum) {
            svg.innerHTML = `<g stroke="#00E1FF" style="transition: all 0.2s;">
                <line x1="${p1[0]}" y1="${p1[1]}" x2="${p2[0]}" y2="${p2[1]}" fill="transparent" stroke-width="1" />
                <line x1="${p2[0]}" y1="${p2[1]}" x2="${p3[0]}" y2="${p3[1]}" fill="transparent" stroke-width="1" />
            </g>`;
        } else {
            svg.innerHTML = `<g stroke="#00E1FF" style="transition: all 0.2s;">
            <line x1="${p1[0]}" y1="${p1[1]}" x2="${p2[0]}" y2="${p2[1]}" fill="transparent" stroke-width="1" />
            <line x1="${p2[0]}" y1="${p2[1]}" x2="${p3[0]}" y2="${p3[1]}" fill="transparent" stroke-width="1" />
            <circle cx="${p1[0]}" cy="${p1[1]}" r="3" stroke-width="6" stroke-opacity="0.5" fill="#00E1FF" />
        </g>`;
        }
        return svg;
    }

    addHover() {
        this.div.addEventListener('mouseenter', () => {
            this.highlight('active');
            this.div.style.transform = 'scale(1.5)'
        });
        this.div.addEventListener('mouseleave', () => {
            this.unhighlight('active');
            const width = document.documentElement.clientWidth;
            if(width <= 1440) {
                this.div.style.transform = 'scale(1.3)'
            } else {
                this.div.style.transform = 'scale(1)'
            }
        })
    }
}

// window.Mark = Mark;