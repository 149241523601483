<template>
  <div class="mobile-page">
    <GoBack></GoBack>
    <swiper
      class="swiperbox"
      :options="swiperOption"
      ref="mySwiper"
      @click="slideClick"
      @slideChange="slideChange"
    >
      <swiper-slide style="height: 100%">
        <PageOne></PageOne>
      </swiper-slide>
      <swiper-slide style="height: 100%">
        <PageTwo></PageTwo>
      </swiper-slide>
      <swiper-slide style="height: 100%">
        <PageThree></PageThree>
      </swiper-slide>
      <swiper-slide style="height: 100%">
        <PageFour></PageFour>
      </swiper-slide>
      <swiper-slide style="height: 100%">
        <PageFive></PageFive>
      </swiper-slide>
      <swiper-slide style="height: 100%">
        <PageAbout></PageAbout>
      </swiper-slide>
    </swiper>
    <NavMenu :activeIndex="activeIndex"></NavMenu>
    <CalcVh></CalcVh>
  </div>
</template>
<script>
import GoBack from "@/components/GoBack";
import NavMenu from "@/components/NavMenu";
import PageOne from "./components/PageOne";
import PageTwo from "./components/PageTwo";
import PageThree from "./components/PageThree";
import PageFour from "./components/PageFour";
import PageFive from "./components/PageFive";
import PageAbout from "./components/PageAbout";
import CalcVh from "@/components/CalcVh";
export default {
  name: "mobileView",
  components: {
    GoBack,
    NavMenu,
    PageOne,
    PageTwo,
    PageThree,
    PageFour,
    PageFive,
    PageAbout,
    CalcVh,
  },
  data() {
    return {
      swiperOption: {
        direction: "vertical",
      },
      // activeIndex: this.$refs.mySwiper.swiper.activeIndex
      activeIndex: 0
    };
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.swiper;
    },
    // activeIndex() {
    //   return this.$refs.mySwiper.swiper;
    // },
  },
  mounted() {
    this.$bus.$on("page-slide-to", this.slideTo.bind(this));
  },
  methods: {
    slideClick() {
      this.$bus.$emit("page-slide-click");
    },
    slideTo(index) {
      this.swiper.slideTo(index, 1000, false);
    },
    slideChange() {
      this.$bus.$emit("page-slide-change", this.swiper.activeIndex);
      this.activeIndex = this.swiper.activeIndex;
    },
  },
};
</script>
<style lang="less" scoped>
.mobile-page {
  background: url("@/assets/img/bg.jpg") no-repeat;
  background-size: 100vw 100vh;
}
.swiperbox {
  height: calc(var(--vh) * 100);
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: url("@/assets/img/bg.jpg") no-repeat;
  background-size: 100% 100%;
}
</style>
