<template>
  <div>
  </div>
</template>

<script>
import { isMobileFn } from "@/common/utils";

export default {
  name: 'HomeView',
  data() {
    return  {
      isMobile: isMobileFn()
    }
  },
  created(){
    if(this.isMobile){
      this.$router.push('mobile')
    } else {
      this.$router.push('pc')
    }    
  }

}
</script>
