<template>
  <div class="pageonebox">
    <section class="logbox">
      <img class="logoimg" src="@/assets/img/logo1-1.png" alt="" />
    </section>
    <section class="titlebox">执数智之器 · 精水务之业</section>
    <section class="textbox">
      <p class="text_indent">
        北京云建标科技有限公司成立于2015年，技术团队来自北控水务、威立雅、中信国安智慧城市、中国建筑标准设计研究院、阿里等，专注于水务/水利数字化建设运营。秉持“执数智之器，精水务之业”的理念，我们将AI、BIM、GIS、边云协同等技术应用于水务、水环境和水利的建设、运营、管理中，立志于成为“最懂运营管理的智慧水务科技公司”。
      </p>
      <p class="text_indent">
        借助与水务行业领先企业的深入合作，创新性地实现了将水务运营管理经验与信息化技术、自控技术、专业模型的深度融合，打造集“精确感知+无缝协同+智慧决策”为一体的多业态运营管理能力，赋能水务企业数字化转型，助力政府涉水业务的精细化监管。
      </p>
      <p class="text_indent">
        公司为国家高新技术企业和中国水协智慧水务专业委员会委员单位，企业信用等级AAA，具备CMMI3、电子与智能专业承包二级、ITSS资质，经过ISO9000、ISO20000等认证，拥有30多项软件著作权和软件产品认证，10多项智慧水务硬件发明专利。
      </p>
    </section>
    <div class="newsbox">
      <div class="newtitle">
        <span class="englishtitle">NEWS</span>
        <span class="chinesetitle">云建标新闻</span>
      </div>
      <div class="report">
        <img src="@/assets/img/pc/news15.png" alt="newsImage" />
        <div class="newslistbox">
          <p
            class="news-item"
            v-for="item in newsList"
            :key="item.desc"
            @click="goto(item.link)"
          >
            <span class="date">{{ item.date }}</span>
            <span class="newsreport">{{ item.desc }}</span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      newsList: [
        // {
        //   date: "23/04/19",
        //   desc: "云建标圆满亮相中国水协年会",
        //   link: "https://mp.weixin.qq.com/s/booD-CFsy6ss8BVQ2cwAsw",
        // },
        // {
        //   date: "23/02/20",
        //   desc: "以“数字孪生”打造新一代未来水厂",
        //   link: "https://mp.weixin.qq.com/s/SF6H2XVAJ_nMTs0pBOo4DA",
        // },
        // {
        //   date: "20/03/22",
        //   desc: "新起点，新征程。经过2019年的快速成长，北京云建标科技有限公司...",
        //   link: "https://mp.weixin.qq.com/s/sS4KGFF5toaa37Fhu2_jFQ",
        // },
        {
              desc:'云建标CWPilot防汛调度系统助力秦皇岛区域公司完成强降雨应对',
              date: "23/08/02",
              link: "https://mp.weixin.qq.com/s/AqS1xQ1lSxj9Owo95FUp6Q",
            },
             {
              desc:'CWVisual助力环球影城水环境运维智慧化',
              date: "23/07/18",
              link: "https://mp.weixin.qq.com/s/P8ooaF8Wog3G9iZ-ewuRdA",
            },
             {
              desc:'云建标参编的《城镇水务数据分类编码及主数据识别规则》正式发布',
              date: "23/06/21",
              link: "https://mp.weixin.qq.com/s/I9kBtt_K-Jm6UP7xD7z5WA",
            },
      ],
    };
  },
  methods: {
    goto(url) {
      window.open(url);
    },
  },
};
</script>

<style lang="less" scoped>
.pageonebox {
  color: #fff;
  font-size: 12px;
  padding:0 5%;
  height: 100%;
}

.logbox {
  margin-top: calc(var(--vh) * 60 / 6.64 );
  text-align: center;
  .logoimg {
    width: 100px;
    height: auto;
    object-fit: cover;
  }
}
.titlebox {
  margin-top: calc(var(--vh) * 20 / 6.64 );
  text-align: center;
}
.textbox {
  margin-top: calc(var(--vh) * 20 / 6.64 );
  .text_indent {
    &:first-child {
      margin-top: 0;
    }
    margin-top: 7px;
    text-indent: 2em;
    line-height: 1.25;
  }
}
.newsbox {
  margin-top: calc(var(--vh) * 20 / 6.64 );
  .englishtitle {
    font-size: 18px;
    margin-right: 5px;
  }
  .report {
    margin-top: 10px;
    display: flex;
    img {
      width: 90px;
      height: auto;
      margin-right: 10px;
    }
    .newslistbox {
      .news-item {
        margin-top: 10px;
        width: 60vw;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }
  }
}
</style>
