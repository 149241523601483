<template>    
    <div class="web-page" :class="`bgImg${curIndex}`">
      <TopBar :curIndex="curIndex" :style="curIndex === 0 ? 'display: none' : 'display: flex'"></TopBar>

      <div class="left">
        <div class="computer" v-if="curIndex > 0">
            <img src="@/assets/img/logo/logo-s.png">
            <ul>
                <li v-for="(item,index) in listTitle" style="cursor: pointer" :key="index" :class="index ===(curIndex-1)? 'active-circle': ''" @click="skipPage(index + 1)" ></li>
            </ul>
        </div>
      </div>
      <div class="right"> 
        <GoBack :isPC="true"></GoBack>
        <swiper
            class="swiperbox"
            :options="swiperOption"        
            ref="mySwiper"
            @click="slideClick"
            @slideChange="slideChange"
        >
            <swiper-slide style="height: 100%">
            <PageDefault></PageDefault>
            </swiper-slide>
            <swiper-slide style="height: 100%">
            <PageOne></PageOne>
            </swiper-slide>
            <swiper-slide style="height: 100%">
            <PageTwo></PageTwo>
            </swiper-slide>
            <swiper-slide style="height: 100%">
            <PageThree></PageThree>
            </swiper-slide>
            <swiper-slide style="height: 100%">
            <PageFour></PageFour>
            </swiper-slide>
            <swiper-slide style="height: 100%">
            <PageFive></PageFive>
            </swiper-slide>
            <swiper-slide style="height: 100%">
            <PageAbout></PageAbout>
            </swiper-slide>
        </swiper>
      </div>

    </div>
  </template>
  <script>
  import GoBack from "@/components/GoBack";
  import PageDefault from "./components/PageDefault";
  import PageOne from "./components/PageOne";
  import PageTwo from "./components/PageTwo";
  import PageThree from "./components/PageThree";
  import PageFour from "./components/PageFour";
  import PageFive from "./components/PageFive";
  import PageAbout from "./components/PageAbout";
  import TopBar from "./components/TopBar.vue";


  export default {
    name: "mobileView",
    components: {
      GoBack,
      PageDefault,
      PageOne,
      PageTwo,
      PageThree,
      PageFour,
      PageFive,
      PageAbout,
      TopBar
    },
    data() {
      return {
        curIndex: 0,
        swiperOption: {
          direction: "vertical",
          mousewheel : true
        },
        listTitle :[
            {
                id:0,
                name:"公司简介"
            },
            {
                id:1,
                name:"解决方案"
            },
            {
                id:2,
                name:"核心产品"
            },
            {
                id:3,
                name:"成功案例"
            },
            {
                id:4,
                name:"关于我们"
            },
            {
                id:5,
                name:"联系我们"
            },
        ]
      };
    },
    computed: {
      swiper() {
        return this.$refs.mySwiper.swiper;
      }
    },
    mounted() {
      this.$bus.$on('page-slide-to', this.slideTo.bind(this));
      // this.swiper.slideTo(5, 1000, false);
    },
    methods: {
      slideClick(){
        this.$bus.$emit('page-slide-click');
      },
      slideTo(index) {
        this.swiper.slideTo(index, 1000, false);
      },
      slideChange() {
        this.curIndex = this.swiper.activeIndex;
        this.$bus.$emit('page-slide-change', this.swiper.activeIndex);
      },
      skipPage(index){
        console.log(index,'111')
        this.$bus.$emit('page-slide-to', index);
      },
    },
  };
  </script>
  <style lang="less" scoped>
  .web-page {
    background-attachment: scroll;
    display: flex;
    flex-direction: row;
  }
  .bgImg0 {
    background: url('@/assets/img/pc/bg/bg0.png') no-repeat;
    background-size: 100vw 100vh;
  }
  .bgImg1 {
    background: url('@/assets/img/pc/bg/bg1.png') no-repeat;
    background-size: 100vw 100vh;
  }
  .bgImg2 {
    background: url('@/assets/img/pc/bg/bg2.png') no-repeat;
    background-size: 100vw 100vh;
  }
  .bgImg3 {
    background: url('@/assets/img/pc/bg/bg3.png') no-repeat;
    background-size: 100vw 100vh;
  }
  .bgImg4 {
    background: url('@/assets/img/pc/bg/bg4.png') no-repeat;
    background-size: 100vw 100vh;
  }
  .bgImg5 {
    background: url('@/assets/img/pc/bg/bg5.png') no-repeat;
    background-size: 100vw 100vh;
  }
  .bgImg6 {
    background: url('@/assets/img/pc/bg/bg5.png') no-repeat;
    background-size: 100vw 100vh;
  }
  .left{
    width: 150px;
    height: 100vh;
    .computer {
       position: relative;
       top:50%;
       left:50%;
       transform: translate(-50%,-50%);
       display: flex;
       flex-direction: column;
       
       align-items: center;
       img {
        align-self: center;
        width: 53px;
        margin-bottom: 40px;
       }
       ul {
        padding-inline-start:0px;    
       }
       li {      
        width: 10px;
        height: 10px;
        background-color: #fff;
        border-radius: 50%;
        margin: 20px auto 0px;
        list-style: none;        
       }
       .active-circle {
        width: 15px;
        height: 15px;
      }
    }
  }
  .right {
    flex:1;
    height: 100vh;
    .swiperbox {
        position: fixed;
        top: 0;
        right: 0;
        left: 150px;
        bottom: 0;
    }
  }

  </style>
  