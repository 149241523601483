<template>
    <div class="recruit" id="page">
      <div class="back" @click="handleBack"></div>
      <div class="title">
          <p class="en-title">POST&ensp;RELEASE</p>
          <p class="cn-title">职位发布</p>
      </div>
      <div class="content">
          <div class="job" v-for="(item,index) in recruitList" :key="index" :id="'job'+index">
              <div class="command" >
                  <div class="brief">
                    <div class="title-head"> 
                      <span class="job-name">{{ item.jobName }}</span>
                      <img :src="location" alt="">
                      <span class="location">{{ item.location }}</span>
                      <span class="experience">{{item.experience}}</span>
                      <span class="edu-require">{{item.eduRequire}}</span>
                    </div>
                    <div class="title-tail"> 
                      <span @click="handleDetail(index)" class="detail-info">职位描述</span>
                      <span @click="handleEmail" class="detail-info" :data-value="item.jobName">我要应聘</span>
                    </div>
                      
                  </div>
                  <div v-if="showJobDetail[index]" class="detail">
                      <div class="tit">{{ item.descTitle }}</div>
                      <ul>
                          <li v-for="(itemLi,id) in item.descDetail" :key="id">{{ itemLi }}</li>                           
                      </ul>
                  </div>
              </div>
          </div>
      </div>
    </div>
</template>

<script>
import location from '@/assets/img/loatcation.png';

export default {
    data() {
        return {
          location,
          showJobDetail:[],
          skipID: -1,
          divId: 0,
          recruitList:[
            {
              id:0,
              jobName:"区域销售经理",
              location:"北京",
              experience:"3-5年",
              eduRequire:"大专",
              descTitle:"岗位职责",
              descDetail:[
                "1、负责公司智慧水务产品和解决方案在北控水务内部各大区的销售，包括排水、管网、供水、水环境；",
                "2、跟踪公司和北控水务在各区域的对外合作项目信息，协调资源，跟踪并完成项目销售；",
                "3、销售计划的制定与执行，完成销售任务指标；",
                "4、公司安排的其他事项；",
                "5、上海和广州职位以SOHO形式办公。",
                "任职资格：",
                "1、具有3年以上销售经验和成功案例；",
                "2、具有商务谈判技巧和价格谈判能力；",
                "3、良好的逻辑思维、沟通、语言表达能力和应变能力，有商务文案处理能力；",
                "4、具备如下条件者优先：",
                "a) 水务行业技术背景或从业背景优先；",
                "b) 供排水、环境工程、自动化控制及相关专业，本科及以上学历。",
              ]
            },
            {
              id:1,
              jobName:"行业销售总监",
              location:"北京",
              experience:"5-10年",
              eduRequire:"大专",
              descTitle:"岗位职责",
              descDetail:[
                "1、负责公司智慧水务解决方案的外部市场拓展，包括排水、管网、供水、水环境；",
                "2、独立拓展水司、水务/水环境运营商、EPC总包方的水务智慧化项目；",
                "3、跟踪公司和北控水务的项目信息，协调资源，进行外部智慧水务项目销售；",
                "4、营销计划的制定与执行，完成销售任务指标；",
                "5、公司安排的其他事项；",
                "6、上海和广州职位以SOHO形式办公。",
                "任职资格：",
                "1、具有3年以上水务行业的产品、解决方案销售经验和成功案例；或具有水司、水务/水环境、EPC总包，及相关设计院的从业经历；",
                "2、具有商务谈判技巧和价格谈判能力；",
                "3、良好的逻辑思维、沟通、语言表达能力和应变能力，有商务文案处理能力；",
                "4、具备如下条件者优先：",
                "a) 供排水、环境工程、自动化控制、IT计算机及相关专业，本科及以上学历；",
                "b) 水务行业技术背景。",
              ]
            },
            {
              id:2,
              jobName:"售前咨询",
              location:"北京",
              experience:"5-10年",
              eduRequire:"本科",
              descTitle:"岗位职责",
              descDetail:[
                "1、配合销售团队进行项目开发，与客户高层领导进行方案宣讲，部门需求调研，设计编写解决方案，并参与专家汇报、评审，招投标等工作；",
                "2、跟踪政策动向、行业市场动态，解读工作报告，分析现状、未来发展趋势及竞争对手对比，不断优化水务信息化相关产品方案，以适应行业发展、贴合客户需求；",
                "3、根据客户需求，进行市场调研分析，寻找相关领域合作伙伴，多维度评估产品方案，配合商务谈判，选择确定最匹配合作伙伴，集成整合方案；",
                "4、详细梳理项目需求，进行软件产品的功能设计，协助产品经理梳理用户故事，督导开发、测试，并在项目部署阶段支撑实施工作。",
                "任职资格：",
                "1、本科及以上学历，计算机相关专业；",
                "2、5年以上IT行业工作经验，拥有MES产品/水务信息化/大数据可视化产品的设计研发测试经验优先；",
                "3、掌握IT咨询规划方法，了解ICT解决方案设计与规划，具备较好的技术功底，有较强的文字功底，能够独立撰写技术方案，较强的研究能力与技术跟踪意识；",
                "4、熟悉并掌握软件工程、项目管理等相关理论与基本知识，熟悉云计算、大数据、物联网等技术",
                "5、逻辑思维能力强，具备良好的沟通和表达能力；",
                "6、具有较强的敬业精神和良好的心理素质,有较强的计划、组织、学习能力;",
                "7、具有良好的团队合作精神。"
              ]
            },
            {
              id:3,
              jobName:"产品经理",
              location:"北京",
              experience:"3-5年",
              eduRequire:"本科",
              descTitle:"岗位职责",
              descDetail:[
                "1、深入调研用户业务流程，挖掘痛点需求，梳理业务流程，能独立提出解决方案并完成产品规划、功能设计；",
                "2、跟踪政策动向、行业市场动态，分析现状、未来发展趋势及竞争对手对比，策划及执行前瞻型产品；",
                "3、对产品进行可用性测试和评估，提出改进方案，持续优化产品的用户体验；",
                "4、制作产品原型图，撰写用户需求规格说明书和产品设计文档；",
                "5、拆解产品开发任务，规划进度安排，协调公司技术力量，跟踪产品开发、测试，部署过程，把控交付质量。",
                "任职资格：",
                "1、本科及以上学历，计算机相关专业；",
                "2、3年以上产品相关工作经验，拥有水务、水利、水环境信息化/大数据可视化产品的设计研发测试经验优先；",
                "3、对数据类应用系统、互联网产品等有良好理解，乐于总结和创新；",
                "4、逻辑思维能力强，具备良好的沟通和表达能力；",
                "5、具有较强的敬业精神和良好的心理素质,有较强的计划、组织、学习能力; ",
                "6、具有良好的团队合作精神。"
              ]
            },
            {
              id:4,
              jobName:"网络实施工程师",
              location:"北京",
              experience:"3-5年",
              eduRequire:"本科",
              descTitle:"岗位职责",
              descDetail:[
                "1) 负责项目中网络规划、组织及监督相关具体网络实施及质量把关工作；",
                "2) 能够按照用户方现场情况及具体网络建设要。求，进行具体的设计、组织、对接工作；",
                "3) 负责硬件设备具体落地实施、安装的质量把关，做到计划与实施工作有条不紊的推进；",
                "4) 能够根据实际业务需要对硬件设备提出高质量的选型建议",
                "任职资格：",
                "1) 三年以上大中型网络系统实施经验；",
                "2) 精通主流厂家（思科、华为、华三、Juniper）的路由器、交换机、网络安全等相关设备；",
                "3) 可独立进行安装、调试设备，排查故障；",
                "4) 具备一定的网络架构设计能力，文档编写能力（技术方案、实施方案、故障报告等）；",
                "5) 熟悉TCP/IP协议和ISIS/OSPF/BGP等路由协议，熟悉大中型网络架构及相应的技术（如 MPLS、QoS、IPv6）；",
                "6) 具备优秀的学习能力，出色的沟通能力；",
                "7) 统招本科及以上学历，计算机、软件工程、电子等相关专业；",
                "8) 具有CCNP、CCDP或其它厂家同等认证。"
              ]
            },
            {
              id:5,
              jobName:"项目实施工程师",
              location:"北京",
              experience:"1-3年",
              eduRequire:"本科",
              descTitle:"岗位职责",
              descDetail:[
                "1、负责智慧水务系统项目的实施工作；",
                "2、负责智慧水务系统项目实施方法的制订、推广、知识管理等；",
                "任职资格：",
                "1、大学本科学历，计算机、通信、电子等相关专业；",
                "2、具备2年以上项目实施经验或系统运维经验；",
                "3、熟悉主流服务器操作系统、数据库、应用中间件，网络设备的安装、调试及优化等；",
                "4、有一定的软件开发经验或水务行业工作经验者优先。",         
              ]
            },
            {
              id:6,
              jobName:"项目经理",
              location:"北京",
              experience:"3-5年",
              eduRequire:"本科",
              descTitle:"岗位职责",
              descDetail:[
                "1、负责智慧水务项目前期的客户沟通、需求引导、工作量评估、报价整理、文档编写等工作；",
                "2、负责项目执行过程中的需求管理、进度与计划管理、质量把控、资源协调、文档编写及客户沟通工作；",
                "3、负责项目后期交付过程协调、会议组织、部署实施与培训组织以及文档编写工作。",
                "任职资格：",
                "1、本科及以上学历，计算机、通信、电子等相关专业；",
                "2、具备较强的逻辑思维能力和沟通表达能力；",
                "3、具备一定项目管理/协调经验和较强的文档编写能力；",
                "4、具备3-5年项目管理工作经验；",
                "5、具备PMP或信息系统项目管理师等相关认证；",
                "6、有水务行业软件开发/管理经验经验者优先考虑。"
              ]
            },
          ]
        }
    },
    created(){
      this.skipID = this.$route.params.curId;
      // console.log('created====this.skipID=',this.skipID)
      if(this.skipID != 7){
        this.divId = `job${this.skipID}`;
        this.$set(this.showJobDetail,this.skipID,true);
      }
    },
    mounted(){
      if(this.skipID != 7){
        this.$nextTick(()=>{
          let parent = document.getElementById('page');
          let target = document.getElementById(this.divId);
          const scrollHeight = target.offsetTop - parent.offsetTop;
          parent.scrollTop = scrollHeight;
        })

      }
      

    },
    methods: {
      handleBack(){
        this.$router.go(-1);              
      },
      handleDetail(index) {
        if(this.showJobDetail[index]){
          this.$set(this.showJobDetail,index,false);
        } else {
          this.$set(this.showJobDetail,index,true);
        }       
      },
      handleEmail(e){
        // console.log('handleEmail====e=',e,e.target.dataset.value);
        window.location.href="mailto:hr@cyberwater.cn?subject=我要应聘——"+e.target.dataset.value;
      }
    }
};
</script>

<style lang="less" scoped>
.recruit {  
  // height: 100vh;
  background: #011C31;
  padding: 0 10% 50px;
  overflow: auto;

  .title {
    text-align: center;
    .en-title {
      font-size: 16px;
      color: #ccc;
      margin-top: 72px;
    }
    .cn-title {
      margin-top: 65px;
      font-size: 38px;
      color: #fff;
      margin-bottom: 80px;
    }
  }
  .content {
    .job {
      border-top: 2px solid #dcdcdc;
      // overflow: hidden;
      // transition: all 0.7s ease;
      .brief {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        min-height: 147px;
        .title-head {
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          align-items: center;
          .job-name {
            color: #fff;
            font-size: 14px;
            margin-right: 40px;
          }
          img {
            width: 40px;
            height: 40px;
            align-self: center;
            margin-right: 10px;
          }
          .location,.experience,.edu-require {
            color: #d0d0d0;
            font-size: 13px;
            margin-right: 20px;
          }
        }
        .title-tail {
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          .detail-info {
            align-self: center;
            color: #0096e7;
            font-size: 14px;
            display: inline-block;
            width: 107px;
            height: 39px;
            line-height: 35px;
            text-align: center;
            box-sizing: border-box;
            border: 2px solid #0096e7;
            border-radius: 20px;
            cursor: pointer;
            margin-left: 30px;
          }
          .detail-info:first-child(1) {
            margin-left: 0px;
          }
          .detail-info:hover {
            color: #ffffff;
            background-image: linear-gradient(to right, #8698fa, #3c74db);
            border: 2px solid #8698fa;
          }
        }


      }
      .detail {
        display: flex;
        flex-direction: column;
        flex-direction: left;
        .tit {
          font-size: 16px;
          color: #fff;
          display: inline-block;
          height: 16px;
          line-height: 16px;
          margin-bottom: 20px;
        }
        li {
          font-size: 14px;
          color: #fff;
          margin-bottom: 20px;
          list-style: none;
        }
      }

    }
    .job.active {
      height: auto;
      padding-bottom: 40px;
    }
    .job:last-of-type {
      border-bottom: 2px solid #dcdcdc;
    }
  }
}

</style>
