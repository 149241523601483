<template>
  <div class="page4box">
    <TransitionGroup name="fade">
      <div key="pagemain" v-show="!isShowDetail" class="pagemain">
        <div class="mainrow" v-for="(item, index) in dataList" :key="index">
          <div class="rowname">{{ item.title }}</div>
          <SwiperBox
            class="swiperbox"
            :dataList="item.cardList"
            :swiperOption="swiperOption"
          >
            <template #default="data">
              <div class="cardbox" @click="showDetail(data)">
                <img class="cardimg" :src="data.img" :alt="data.name" />
                <div class="cardname">{{ data.name }}</div>
              </div>
            </template>
          </SwiperBox>
        </div>
      </div>
      <div key="carddetail" v-show="isShowDetail" class="carddetail">
        <img class="detailimg" :src="detailInfo.img" alt="" />
        <p class="detailtitle">{{ detailInfo.name }}</p>
        <p class="detaildesc">{{ detailInfo.desc }}</p>
      </div>
    </TransitionGroup>
  </div>
</template>

<script>
import SwiperBox from "@/components/SwiperBox";
import tianjin from "@/assets/img/case/tianjin.jpg";
import beijing from "@/assets/img/case/beijing.jpg";
import sichuan from "@/assets/img/case/sichuan.jpg";
import kaili from "@/assets/img/case/kaili.jpg";
import neimenggu from "@/assets/img/case/neimenggu.jpg";
import qinhuangdao from "@/assets/img/case/qinhuangdao.jpg";
import heshan from "@/assets/img/case/heshan.jpg";
import huanqiu from "@/assets/img/case/huanqiu.jpg";
import xianggang from "@/assets/img/case/xianggang.jpg";
import shanghaishuili from "@/assets/img/case/shanghaishuili.jpg";
import shanghaichengtou from "@/assets/img/case/shanghaichengtou.jpg";
import pudong from "@/assets/img/case/pudong.jpg";
import yinchuan from "@/assets/img/case/yinchuan.jpg";
import taziba from "@/assets/img/case/taziba.jpg";
import beijngdaoxianghu from "@/assets/img/case/beijngdaoxianghu.jpg";
import fangzheng from "@/assets/img/case/fangzheng.jpg";
import weining from "@/assets/img/case/weining.jpg";
import xian from "@/assets/img/case/xian.jpg";
import jiangmen from "@/assets/img/case/jiangmen.jpg";
import zhangjiakou from "@/assets/img/case/zhangjiakou.jpg";

export default {
  components: {
    SwiperBox,
  },
  data() {
    return {
      isShowDetail: false,
      detailInfo: {},
      dataList: [
        {
          title: "集团数字化运营",
          cardList: [
            {
              img: tianjin,
              name: "天津创业环保南部区运管平台",
              desc: "该项目为天津创业环保南部区域公司及其下属的10个子公司（16个污水处理厂）建设运营管理平台，为用户提供覆盖利润、收入、成本、资产、安全、质量等业务的数字化管理工具。平台采用统一的标准融合了水务企业管理涉及的多源数据，打通了层级之间和部门之间的业务流程，能够同时辅助决策人员、管理人员和操作人员开展经营和运营管理工作。平台有效提升了数据归集效率，深度挖掘数据应用价值，为业务决策提供了及时、准确的信息依据。线上化的运营管理工具使得运营工作更加规范化、精益化，实现工作计划有序、执行高效，过程可监控可追溯可量化评价，使得企业的整体管理水平得到提升。",
            },
            {
              img: beijing,
              name: "北控水务集团智慧水务",
              desc: "利用数字化技术手段帮助用户对区域内市政、村镇和水环境业务开展集约化、标准化、高质量的统一管理，通过提升自动化水平、建设区域智慧控制中心、部署运营管理平台实现水务多业态统一调度、集中管控、智慧运营、少人值守的目标。完成现场运行数据和重点部位视频的全采集，对重点工艺设备进行健康状态评估诊断及预测性维护，实现地下水厂人员精准定位、厂站设备管理和生产运行管理数字化、线上化。通过人机互联、互通、互动，数据网络化共享、集约化整合和高效化利用，为稳定运行、安全生产、敏捷管控提供有力的保障，并实现节能降耗。",
            },
            {
              img: sichuan,
              name: "四川眉山村镇污水智慧运营",
              desc: "以数字化技术支撑用户对34个村污厂站开展集约化管理。分片区设置集中控制中心，在中心部署大屏系统、集控平台、坐席等；搭建网络，实现在中心查看辖区内所有分厂、村污站点的运行数据和视频画面，并支持对厂站设备远程控制。部署城乡一体化运营管理云平台，为用户提供工艺监控、业务分析、绩效考核、设备管理、报表统计及输出、移动应用等功能，利用图像识别、大数据等各类技术和业务模型实现关键问题自主识别与预警，形成“基于数据的片区管理+站点无人值守”新模式。最终使得用户的运营体系更加高效、生产更加智能、管理更加精细、决策更加科学、服务更加聚焦，提高村污业务的核心竞争力和经济效益。",
            },
            {
              img: kaili,
              name: "贵州凯里智慧供水",
              desc: "辅助客户理顺业务流程，建立数据标准，将供水运营线下能力转化线上工具。建设智慧供水系统，采用SaaS化部署方式，实现灵活配置、远程升级、快速推广应用。使得供水业务全面感知和可视化，资产清晰，人员轨迹、工作绩效、工单流转明晰，为产销差管理提供更多抓手。通过“线上工单过程透明，计件薪酬多劳多得”，帮助用户解决管网漏失问题。在“控漏”的同时关注“止损”，通过营销管理系统辅助用户对水表选型、水表安装、抄表质量、计量评估、用水稽查过程管理，针对性地解决计量问题。为民众提供多渠道缴费和主动及时的服务，不断提升客户满意度、改善政府营商环境。",
            },
            {
              img: neimenggu,
              name: "内蒙古科源供水一体化",
              desc: "通过数字化和自动化技术手段提升供水泵站设备自动控制运行能力和运营管理信息化水平，实现智控中心对所有泵站的全面监管、控制和泵站无人值守。为用户的六处供水泵站打造集中控制中心，改造机房及中控室，对泵站进行自控消缺和数字化改造，增设视频监控点位，并完成泵站与中心的网络打通，实现各个泵站设备运行信息同步上传，控制联动。部署厂网管理系统，实现用户基于一张图进行泵站管理、巡检管理、班组管理、工单管理，依靠集群后的技术、人力资源集中的优势，保障运营有序，提高运行管理水平和效率，进而减少值守人员，实现降低成本和提高经济收益的双重目标。",
            },
          ],
        },
        {
          title: "一体化业务管理",
          cardList: [
            {
              img: qinhuangdao,
              name: "秦皇岛厂网一体化",
              desc: "项目整合城域范围内4座污水处理厂、2座污泥处理厂、5个排水管理处、51座泵站、400多公里管网，统一纳入厂网一体化平台精细管理，彻底打破信息孤岛，部门壁垒。利用实时数据+AI模型驱动厂网站有序联动，防汛响应处置效率显著提升，真正实现污水系统的自我感知、 自我诊断、自我调节和自我平衡。平台提供排水综合决策、防汛、管网管理、泵站调度、污水厂运营、厂网一体化运营等业务场景功能等，开创全国首例厂网一体化运营模式，为排水系统高效运行提供系统化的解决方案保证高效决策、高效处理和管理，充分发挥水环境保障功能，全面提升水务管理的效率和效益。",
            },
            {
              img: heshan,
              name: "鹤山水环境二三维智慧管控",
              desc: "项目将鹤山市纳入河长制管理范围的45条河流、74宗水库、32宗重点山塘及相关管网、污水处理厂的基础数据、实时检测数据统一整合，构建空天地一体化的监测体系，同时获取厂、网、河的实时运行信息。通过BIM+数字孪生技术对真实场景的精准还原，移动端、电脑端、大屏幕多屏联动，全面实现管理可视化。同时该项目创新性地无人机影像和高光谱监测，并利用三维模型表达水体各区间端的污染情况，所见即所得，一张图、多场景全景展现河长制建设成效。",
            },
            {
              img: huanqiu,
              name: "环球影城水环境二三维管控",
              desc: "项目通过搭建水环境感知一张网，对重点关键环节、传感器设备、水厂设备的运行状态进行监视、数据分析。在此基础上构建三维场景下的统一监测、运行、管理平台，实现“人事物”在线的三维场景水环境全要素监测管控，对景观水系、水循环、净化系统实现了智能化管理，保障环球影城整个河道环境和水厂运行安全稳定的运行。",
            },
            {
              img: xianggang,
              name: "香港近海环卫数字化管理",
              desc: "项目以海洋垃圾收、运、处全业务链的数字化全生命周期管理为核心、构建一体化、标准化、可视化的陆海统筹、河湖联动一体化管控平台，针对于作业区域、收运船只、执行班组、位置轨迹、垃圾处置等业务进行数据监控、工单线上流转，同时政府部门紧密结合形成无缝对接，第一时间处置突发及市民上报事件，辅助香港近海环卫管理部门及运营机构业务监管及运营管理，海洋垃圾管理及相关作业船只的全过程的指挥调度，提高企业收运效率保障垃圾及时处置，推进绿色海洋垃圾治理新时代新步伐。",
            },
            {
              img: shanghaishuili,
              name: "上海泵闸署智慧防汛",
              desc: "我司与上海市水利工程设计研究院有限公司（以下简称“水利院”）达成战略合作，充分融合水利院先进的防汛业务理念与我司丰富的水利信息化开发能力与实践经验，联合研究开发【FPM®数字孪生智慧水利防汛管理孪生系统】，构建了防汛保障区多个数字孪生闸站，实现了在三维场景下一体化的业务流转，同时系统集成水利院流域水文水动力专业模型，帮助水利防汛“四预”更加科学精准。系统已经于2022年7月上线运营，为高标准防汛保障区上海某区域流域水利防汛指挥、泵闸运营管理、流域综合治理打造“一站式、一体化”的综合管理平台，实现资源共享、数据挖掘、决策支撑、虚实互馈，构建起新型水利数字孪生基础设施，创新防汛管理新模式。",
            },
          ],
        },
        {
          title: "BIM+数字孪生",
          cardList: [
            {
              img: shanghaichengtou,
              name: "上海城投临港智慧水厂",
              desc: "构建“BIM模型+控制模型+管理模型”驱动的全感知数字孪生水厂，并在此基础之上运用数据融合、三维仿真、机器学习、UWB毫米波定位等技术打造“设备+工艺+人员+事件”的全要素数字化水厂运营管理体系，使得数字孪生成为实体水厂的全维度映射，人事物实时同步“运行”。通过“模型智脑”实现设备故障预知、生产负荷预测、安全风险预警，指导水厂计划性运维、低碳化精准调控、按需生产，使得一线工作人员和各条线管理人员都能够基于数据和算法高效工作、科学决策，水厂能够不断积累、转化专属的数据资产和经验知识，不断寻优，持续升级。",
            },
            {
              img: pudong,
              name: "浦东水务集团海滨污水处理厂智慧水厂",
              desc: "随着浦东水务集团智慧水务建设逐步推进，我公司深入参与编制形成了《浦东水务智慧水务总体发展规划》并在此基础上，承担浦东水务集团海滨污水处理厂数字化升级一期、二期项目，构建智慧水厂体系。项目建立了一套物联网采集平台、基于大数据分析的智能加药系统、设备智能诊断系统及覆盖全流程的生产运营管理平台，实现污水处理成本节约、提升信息化管理水平、降低水厂运行风险，帮助海滨污水处理厂实现运营管理、智能管理的全新升级。",
            },
            {
              img: pudong,
              name: "浦东水务集团南汇北水厂数字孪生水厂",
              desc: "南汇北水厂数字孪生系统构建基于水厂BIM一体化智慧应用管理，数据融合、流程协同，直观查看设备、人员、工单运行情况。同时构建基于三维场景下的各项业务场景应用，包括应急模拟演练、智慧巡检、设备管理、管线管理、图档管理、能耗管理、安全隐患管理、智能安防等全方位三维场景应用，采用集团端-水厂端-移动端、B/S C/S双架构融合部署，打造水三维交互式漫游体验和视觉化的管理方式，为水厂精细化运行管理提供有力支撑，全面提升南汇北水厂运行管理效率和现代化水平。",
            },
            {
              img: yinchuan,
              name: "银川数字孪生污水厂",
              desc: "项目针对银川第一污水处理厂打造BIM模型，实现设计、图纸数字化传承，充分扩展数据的空间属性，从厂区布置、到管线工程、到电气自控工程、甚至到微观的设备内部，采用基于BIM的三维呈现方式，所有信息所见即所得。在BIM模型基础上打造数字孪生系统，融合现场多类业务应用（工艺类、安防类、设备类等）及多源真实数据（SCADA、图纸、视频、台账、工单），形成“一图一屏”全景还原运行态势，数据资产全掌控；创新性提供视觉化运维辅助工具，提供三维交互体验，扩展数据空间属性，端子级呈现，透视隐蔽工程，辅助运维排障高效化、精细化；融合人员定位系统，实现人员位置实时查看、运动轨迹统计分析，辅助优化巡检路线、考核操作规范、保障人员安全。",
            },
            {
              img: taziba,
              name: "塔子坝数字孪生污水厂",
              desc: "将先进的技术手段与行业领先的管理思维相结合，为用户提供一套应用于污水厂物联网接入采集、一体化中央监控、智能化控制、三维可视化管控、大数据分析和科学化决策的智慧化整体解决方案。基于BIM、云计算、机理模型、人工智能等技术，建立规范的业务流程和集约化的管控体系，建设水厂生产运营系统、二三维分析决策支持系统、运营移动互联APP等应用。实现实时数据监控、工艺模拟、能源分析评估、污泥健康诊断、故障远程运维和专家远程协同；提升污水运营效率，提高处理工艺和设备的稳定性和可靠性，节能降耗，降低人工成本。该项目成为用北控水务集团智慧运营典范，塔子坝污水厂被评选为水务行业“双百跨越”标杆型智慧污水厂。",
            },
            {
              img: beijngdaoxianghu,
              name: "北京稻香湖智慧污水厂",
              desc: "为稻香湖再生水厂构建全维度的数字孪生体，形成对水厂景观建筑、设备设施、工艺流程、仪器仪表、人员组织、业务管理等的全面数字映射，在三维空间内所见即所得地展现水厂生产运行状态、设备全生命周期信息、人员位置、告警、工单等信息，结合智能化数据处理、分析和展现，为生产调度、排障维修、安全管理、考核评价提供决策依据。建立生化段工艺仿真模型、部署运营管理平台、能耗监测平台、振动监测系统等先进工具，使得水厂运行更加稳定、维护更加到位、管理更加精细。",
            },
            {
              img: fangzheng,
              name: "携手中信，构建县域智慧城市典范",
              desc: "方正县智慧城市项目旨在通过“一馆、二中心、三平台和九大应用” 即智慧城市体验馆；数据中心、指挥中心；地理信息共享平台、视频共享平台、数据交换共享平台；城管、社管、应急等多个领域的智慧应用建设，将方正县打造成具有区域影响力的智慧城市建设示范区、具有竞争力的智慧经济集聚区、具有辐射力的智慧民生创新先导区，最终实现方智慧正“兴农、强企、精管、善治”的城市愿景。",
            },
            {
              img: weining,
              name: "携手中信，打造县域智慧城市新名片",
              desc: "威宁智慧城市项目可概括为“1+2+3+4+3X”，即：统一建设、两项基础设施、三项硬件基础支撑、四项平台支撑、三大领域智慧应用。项目总投资1个亿，致力于夯实威宁硬件基础，搭建起智慧威宁多层次、立体式的信息化架构，实现绿色、泛在、互联、开放、共享的设计理念，促进政府管理、产业转型和民生服务的长足发展。",
            },
            {
              img: xian,
              name: "管廊智慧运营管理，守护城市生命线",
              desc: "云建标联合中国电建西北勘测设计研究院，共同合作研发智慧管廊运营管理平台，通过BIM+GIS二三维场景与视频监控、沉降监测、人员定位、自动化控制的融合，构建所见即所得的管廊安全监测防护体系，对地下管廊的各种设备、管线监测、安全防护、应急演练、维修保养及工作人员定位等数据进行高效的实时管理和全方位监控，结合线上线下联动巡检，指挥，全方位提速运维执行、应急响应。",
            },
            {
              img: jiangmen,
              name: "地下中微子探测仪建设全流程BIM服务",
              desc: "江门中微子探测实验是在地下700米深的实验厅内建造直径为41米的球形探测器，是目前世界上最大的中微子探测项目，由王贻芳院士牵头全球十多个国家参与联合研究，投资22个亿，工程涉及近二十个专业，其建造难度和所面临的挑战前所未有。云建标凭借优秀的技术能力和多年在BIM+领域的丰富应用经验，成为该项目的综合管控咨询服务单位，为建筑结构、机电、微电子安装提供高精度仿真模拟，并复用模型成果，在未来运维中实现管理维护可视化。",
            },
            {
              img: zhangjiakou,
              name: "携手中国院，助力冬奥会",
              desc: "冬奥会国家滑雪场项目是2019年云建标BIM技术服务领域的重点示范项目，主要是运用BIM技术对国家雪车雪橇中心赛道三维建模，其中赛道全长1.9公里，有16个角度不同、倾斜度各异的弯道，垂直落差超过120米。用户可以通过高精度的三维模型，具象化的呈现整体设计理念，有效提高理解效率，激发设计灵感，同时通过动态碰撞实验，主动识别项目风险，降低试错成本，保障项目安全。",
            },
          ],
        },
      ],
      swiperOption: {
        slidesPerView: 2,
        spaceBetween: 10,
        loop: false,
        autoplay: {
          disableOnInteraction: false,
          waitForTransition: false,
        },
      },
    };
  },
  created() {
    this.$bus.$on("back-click", this.goback);
  },
  methods: {
    showDetail(data) {
      this.detailInfo = data;
      this.isShowDetail = true;
      this.$bus.$emit("back-show", true);
    },
    goback() {
      this.isShowDetail = false;
    },
  },
};
</script>

<style lang="less" scoped>
.page4box {
  color: #fff;
  .pagemain {
    padding: 50px 20px;
    .mainrow {
      .rowname {
        color: #fff;
        height: 40px;
        line-height: 40px;
        font-size: 14px;
        font-weight: 400;
      }

      .cardbox {
        .cardimg {
          width: 100%;
          height: calc(var(--vh) * 18);
          object-fit: cover;
        }
        .cardname {
          opacity: 0.8;
          font-size: 12px;
          font-weight: normal;
          line-height: 30px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
}

.carddetail {
  padding-top: 60px;
  width: 88%;
  margin: 0 auto;
  .back-btn {
    position: absolute;
    left: 0;
    top: 50px;
    display: inline-flex;
    width: 36px;
    height: 36px;
    justify-content: center;
    align-items: center;
  }
  .detailimg {
    width: 100%;
    height: auto;
  }
  .detailtitle {
    color: #fff;
    font-size: 16px;
    line-height: 24px;
    padding: 20px 0 10px;
  }
  .detaildesc {
    color: #fff;
    font-size: 14px;
    opacity: 0.6;
    line-height: 20px;
  }
}
</style>
