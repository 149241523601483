<template>
  <Transition name="fade">
    <div v-show="visible" :class="isPC ? 'back-left': ''" class="backbox" @click="handleBackEvent"></div>
  </Transition>
</template>

<script>

export default {
  props: {
    isPC: {
      type:Boolean,
      default:false
    }
  },
  data() {
    return {
      visible: false,
    };
  },
  created() {
    this.$bus.$on("back-show", this.showBack.bind(this));
    this.$bus.$on("page-slide-change", () => {
      if (this.visible) {
        this.handleBackEvent({type: 'click'})
      }
    });
    this.bindPopStateEvent();
  },
  methods: {
    showBack() {
      this.visible = true;
      // history.pushState?.(null, null, "#detail");
    },
    clickBack() {
      if (this.visible) {
        this.$bus.$emit("back-click");
        this.visible = false;
      }
    },
    handleBackEvent() {
      this.clickBack();
      // if(event.type === 'click') {
      //   history.back();
      // } else {
      //   this.clickBack();
      // }
    },
    bindPopStateEvent() {
      const handler = this.handleBackEvent.bind(this);
      window.addEventListener("popstate", handler, false); //false阻止默认事件
      this.$once("hook:beforeDestroy", () => {
        window.removeEventListener("popstate", handler);
      });
    },
  },
};
</script>

<style lang="less" scoped>
.backbox {
  width: 50px;
  height: 50px;
  background: url("@/assets/img/back.png") no-repeat;
  background-size: 30px;
  background-position: center;
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  // border: 1px solid #fff;
  // border-radius: 50%;
}
.back-left {
  margin-left: 50px;
  top: 64px;
}
</style>
