<template>
  <div class="content">
    <img class="logo" src="@/assets/img/logo1-1.png">
    <div class="title">
        <span>执数智之器 · 精水务之业</span>
    </div>
    <img class="downScroll" src="@/assets/img/pc/icon1.gif">
  </div>
</template>

<script>
export default {
    
    
};
</script>

<style lang="less" scoped>
  .content {
    width:100%;
    height:100%;
    margin-left: -75px;
    .logo {
      margin: 18% auto 4%;
    }
    .title {
      width: 595px;
      margin: 0 auto;
      text-align: center;
      color: white;
      font-size: 24px;
    }
    img {
      margin: 0 auto;
      margin-top: 30px;
      display: block;
    }
  }
</style>
