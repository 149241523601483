<template>
    <div class="content content_pc">
        <div class="title4 title_main" :style="showDetail ? 'display: none' : ''">
            成功案例
        </div>
        <div class="block slider digital">
            <div class="location mapbox">
                <!-- <img id="Map" src="./img/map_bg.png" > -->
                <ul class="buttonbox">
                    <li value="icon_01" class="button">
                        <img v-show="!select_icon_01" value="icon_01" src="@/assets/img/pc/legend_normal_icon_01.png" >
                        <img v-show="select_icon_01" value="icon_01" src="@/assets/img/pc/legend_selected_icon_01.png" >
                        <div value="icon_01" class="label">数字水厂</div>
                    </li>
                    <li value="icon_02" class="button">
                        <img v-show="!select_icon_02" value="icon_02" src="@/assets/img/pc/legend_normal_icon_02.png" >
                        <img v-show="select_icon_02" value="icon_02" src="@/assets/img/pc/legend_selected_icon_02.png" >
                        <div value="icon_02" class="label">集团数字运营</div>
                    </li>
                    <li value="icon_03" class="button">
                        <img v-show="!select_icon_03" value="icon_03" src="@/assets/img/pc/legend_normal_icon_03.png" >
                        <img v-show="select_icon_03" value="icon_03" src="@/assets/img/pc/legend_selected_icon_03.png" >
                        <div value="icon_03" class="label">
                            <span value="icon_03">多厂集约化管理</span>
                        </div>
                    </li>
                    <li value="icon_04" class="button">
                        <img v-show="!select_icon_04" value="icon_04" src="@/assets/img/pc/legend_normal_icon_04.png" >
                        <img v-show="select_icon_04" value="icon_04" src="@/assets/img/pc/legend_selected_icon_04.png" >
                        <div value="icon_04" class="label">城乡供水</div>
                    </li>
                    <li value="icon_05" class="button">
                        <img v-show="!select_icon_05" value="icon_05" src="@/assets/img/pc/legend_normal_icon_05.png" >
                        <img v-show="select_icon_05" value="icon_05" src="@/assets/img/pc/legend_selected_icon_05.png" >
                        <div value="icon_05" class="label">水利/水环境</div>
                    </li>
                    <li value="icon_06" class="button">
                        <img v-show="!select_icon_06" value="icon_06" src="@/assets/img/pc/legend_normal_icon_06.png" >
                        <img v-show="select_icon_06" value="icon_06" src="@/assets/img/pc/legend_selected_icon_06.png" >
                        <div value="icon_06" class="label">BIM+数字孪生</div>
                    </li>
                </ul>
                <!-- <div class="map_content"></div> -->
            </div>
        </div>
        <div class="sub-block slider">
                    
                    <div class="wrapper">
                        <img src="@/assets/img/case/huanqiu.jpg"   class="show">

                        <div class="poster"></div>
                        <div class="text-content">
                            <div class="title">
                                <img src="@/assets/img/logo/环球影城.png" >
                                <span>环球影城智慧水务</span>
                            </div>
                            <div class="detail">
                                <p>项目通过搭建水环境感知一张网，对重点关键环节、传感器设备、水厂设备的运行状态进行监视、数据分析。在此基础上构建三维场景下的统一监测、运行、管理平台，实现“人事物”在线的三维场景水环境全要素监测管控，对景观水系、水循环、净化系统实现了智能化管理，保障环球影城整个河道环境和水厂运行安全稳定的运行。
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="sub-block slider">
                    
                    <div class="wrapper w2">
                        <img src="@/assets/img/case/fangzheng.jpg"  class="show">

                        <div class="poster"></div>
                        <div class="text-content">
                            <div class="title">
                                <img src="@/assets/img/logo/logo_zhongxin.png"  class="w_90">
                                <span>携手中信，构建县域智慧城市典范</span>
                            </div>
                            <div class="detail">
                                <p>方正县智慧城市项目旨在通过“一馆、二中心、三平台和九大应用” 即智慧城市体验馆；数据中心、指挥中心；地理信息共享平台、视频共享平台、数据交换共享平台；城管、社管、应急等多个领域的智慧应用建设，将方正县打造成具有区域影响力的智慧城市建设示范区、具有竞争力的智慧经济集聚区、具有辐射力的智慧民生创新先导区，最终实现方智慧正“兴农、强企、精管、善治”的城市愿景。
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="sub-block slider">
                    
                    <div class="wrapper w3">
                        <img src="@/assets/img/case/beijngdaoxianghu.jpg"  class="show">

                        <div class="poster"></div>
                        <div class="text-content">
                            <div class="title">
                                <img src="@/assets/img/logo/北控水务.png" >
                                <span>北京稻香湖智慧污水厂</span>
                            </div>
                            <div class="detail">
                                <p>为稻香湖再生水厂构建全维度的数字孪生体，形成对水厂景观建筑、设备设施、工艺流程、仪器仪表、人员组织、业务管理等的全面数字映射，在三维空间内所见即所得地展现水厂生产运行状态、设备全生命周期信息、人员位置、告警、工单等信息，结合智能化数据处理、分析和展现，为生产调度、排障维修、安全管理、考核评价提供决策依据。建立生化段工艺仿真模型、部署运营管理平台、能耗监测平台、振动监测系统等先进工具，使得水厂运行更加稳定、维护更加到位、管理更加精细。
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="sub-block slider">
                    
                    <div class="wrapper w4">
                        <img src="@/assets/img/case/zhangjiakou.jpg"  class="show">
                        
                        <div class="poster"></div>
                        <div class="text-content">
                            <div class="title">
                                <img src="@/assets/img/logo/logo_zhgjz.png"  class="w_120">
                                <span>携手中国院，助力冬奥会</span>
                            </div>
                            <div class="detail">
                                <p>冬奥会国家滑雪场项目是2019年云建标BIM技术服务领域的重点示范项目，主要是运用BIM技术对国家雪车雪橇中心赛道三维建模，其中赛道全长1.9公里，有16个角度不同、倾斜度各异的弯道，垂直落差超过120米。用户可以通过高精度的三维模型，具象化的呈现整体设计理念，有效提高理解效率，激发设计灵感，同时通过动态碰撞实验，主动识别项目风险，降低试错成本，保障项目安全。
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="sub-block slider">
                    
                    <div class="wrapper w4">
                        <img src="@/assets/img/case/beijing.jpg"  class="show">
                        
                        <div class="poster"></div>
                        <div class="text-content">
                            <div class="title">
                                <img src="@/assets/img/logo/北控水务.png" >
                                <span>北控水务集团智慧水务</span>
                            </div>
                            <div class="detail">
                                <p>利用数字化技术手段帮助用户对区域内市政、村镇和水环境业务开展集约化、标准化、高质量的统一管理，通过提升自动化水平、建设区域智慧控制中心、部署运营管理平台实现水务多业态统一调度、集中管控、智慧运营、少人值守的目标。完成现场运行数据和重点部位视频的全采集，对重点工艺设备进行健康状态评估诊断及预测性维护，实现地下水厂人员精准定位、厂站设备管理和生产运行管理数字化、线上化。通过人机互联、互通、互动，数据网络化共享、集约化整合和高效化利用，为稳定运行、安全生产、敏捷管控提供有力的保障，并实现节能降耗。
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="sub-block slider">
                    
                    <div class="wrapper w5">
                        <img src="@/assets/img/case/neimenggu.jpg"  class="show">

                        <div class="poster"></div>
                        <div class="text-content">
                            <div class="title">
                                <img src="@/assets/img/logo/北控水务.png" >
                                <span>内蒙古科源供水一体化</span>
                            </div>
                            <div class="detail">
                                <p>通过数字化和自动化技术手段提升供水泵站设备自动控制运行能力和运营管理信息化水平，实现智控中心对所有泵站的全面监管、控制和泵站无人值守。为用户的六处供水泵站打造集中控制中心，改造机房及中控室，对泵站进行自控消缺和数字化改造，增设视频监控点位，并完成泵站与中心的网络打通，实现各个泵站设备运行信息同步上传，控制联动。部署厂网管理系统，实现用户基于一张图进行泵站管理、巡检管理、班组管理、工单管理，依靠集群后的技术、人力资源集中的优势，保障运营有序，提高运行管理水平和效率，进而减少值守人员，实现降低成本和提高经济收益的双重目标。</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="sub-block slider">
                    
                    <div class="wrapper w6">
                        <img src="@/assets/img/case/qinhuangdao.jpg"  class="show">

                        <div class="poster"></div>
                        <div class="text-content">
                            <div class="title">
                                <img src="@/assets/img/logo/北控水务.png" >
                                <span>秦皇岛厂网一体化</span>
                            </div>
                            <div class="detail">
                                <p>项目整合城域范围内4座污水处理厂、2座污泥处理厂、5个排水管理处、51座泵站、400多公里管网，统一纳入厂网一体化平台精细管理，彻底打破信息孤岛，部门壁垒。利用实时数据+AI模型驱动厂网站有序联动，防汛响应处置效率显著提升，真正实现污水系统的自我感知、 自我诊断、自我调节和自我平衡。平台提供排水综合决策、防汛、管网管理、泵站调度、污水厂运营、厂网一体化运营等业务场景功能等，开创全国首例厂网一体化运营模式，为排水系统高效运行提供系统化的解决方案保证高效决策、高效处理和管理，充分发挥水环境保障功能，全面提升水务管理的效率和效益。                                   
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="sub-block slider">
                    
                    <div class="wrapper w7">
                        <img src="@/assets/img/case/yinchuan.jpg"  class="show">

                        <div class="poster"></div>
                        <div class="text-content">
                            <div class="title">
                                <img src="@/assets/img/logo/北控水务.png" >
                                <span>银川数字孪生污水厂</span>
                            </div>
                            <div class="detail">
                                <p>项目针对银川第一污水处理厂打造BIM模型，实现设计、图纸数字化传承，充分扩展数据的空间属性，从厂区布置、到管线工程、到电气自控工程、甚至到微观的设备内部，采用基于BIM的三维呈现方式，所有信息所见即所得。在BIM模型基础上打造数字孪生系统，融合现场多类业务应用（工艺类、安防类、设备类等）及多源真实数据（SCADA、图纸、视频、台账、工单），形成“一图一屏”全景还原运行态势，数据资产全掌控；创新性提供视觉化运维辅助工具，提供三维交互体验，扩展数据空间属性，端子级呈现，透视隐蔽工程，辅助运维排障高效化、精细化；融合人员定位系统，实现人员位置实时查看、运动轨迹统计分析，辅助优化巡检路线、考核操作规范、保障人员安全。
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="sub-block slider">
                    
                    <div class="wrapper w8">
                        <img src="@/assets/img/case/xian.jpg"  class="show">

                        <div class="poster"></div>
                        <div class="text-content">
                            <div class="title">
                                <img src="@/assets/img/logo/logo_xian.png"  class="w_100">
                                <span>管廊智慧运营管理，守护城市生命线</span>
                            </div>
                            <div class="detail">
                                <p>云建标联合中国电建西北勘测设计研究院，共同合作研发智慧管廊运营管理平台，通过BIM+GIS二三维场景与视频监控、沉降监测、人员定位、自动化控制的融合，构建所见即所得的管廊安全监测防护体系，对地下管廊的各种设备、管线监测、安全防护、应急演练、维修保养及工作人员定位等数据进行高效的实时管理和全方位监控，结合线上线下联动巡检，指挥，全方位提速运维执行、应急响应。
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="sub-block slider">
                    
                    <div class="wrapper w9">
                        <img src="@/assets/img/case/weining.jpg"  class="show">

                        <div class="poster"></div>
                        <div class="text-content">
                            <div class="title">
                                <img src="@/assets/img/logo/创业环保.png"  >
                                <span>天津创业环保南部区运管平台</span>
                            </div>
                            <div class="detail">
                                <p>该项目为天津创业环保南部区域公司及其下属的10个子公司（16个污水处理厂）建设运营管理平台，为用户提供覆盖利润、收入、成本、资产、安全、质量等业务的数字化管理工具。平台采用统一的标准融合了水务企业管理涉及的多源数据，打通了层级之间和部门之间的业务流程，能够同时辅助决策人员、管理人员和操作人员开展经营和运营管理工作。平台有效提升了数据归集效率，深度挖掘数据应用价值，为业务决策提供了及时、准确的信息依据。线上化的运营管理工具使得运营工作更加规范化、精益化，实现工作计划有序、执行高效，过程可监控可追溯可量化评价，使得企业的整体管理水平得到提升。
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="sub-block slider">
                    
                    <div class="wrapper w11">
                        <img src="@/assets/img/case/mianyang.jpg"  class="show">

                        <div class="poster"></div>
                        <div class="text-content">
                            <div class="title">
                                <img src="@/assets/img/logo/北控水务.png" >
                                <span>塔子坝数字孪生污水厂</span>
                            </div>
                            <div class="detail">
                                <p>将先进的技术手段与行业领先的管理思维相结合，为用户提供一套应用于污水厂物联网接入采集、一体化中央监控、智能化控制、三维可视化管控、大数据分析和科学化决策的智慧化整体解决方案。基于BIM、云计算、机理模型、人工智能等技术，建立规范的业务流程和集约化的管控体系，建设水厂生产运营系统、二三维分析决策支持系统、运营移动互联APP等应用。实现实时数据监控、工艺模拟、能源分析评估、污泥健康诊断、故障远程运维和专家远程协同；提升污水运营效率，提高处理工艺和设备的稳定性和可靠性，节能降耗，降低人工成本。该项目成为用北控水务集团智慧运营典范，塔子坝污水厂被评选为水务行业“双百跨越”标杆型智慧污水厂。
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="sub-block slider">
                    
                    <div class="wrapper w12">
                        <img src="@/assets/img/case/sichuan.jpg"  class="show">

                        <div class="poster"></div>
                        <div class="text-content">
                            <div class="title">
                                <img src="@/assets/img/logo/北控水务.png"  >
                                <span>四川眉山村镇污水智慧运营</span>
                            </div>
                            <div class="detail">
                                <p>以数字化技术支撑用户对34个村污厂站开展集约化管理。分片区设置集中控制中心，在中心部署大屏系统、集控平台、坐席等；搭建网络，实现在中心查看辖区内所有分厂、村污站点的运行数据和视频画面，并支持对厂站设备远程控制。部署城乡一体化运营管理云平台，为用户提供工艺监控、业务分析、绩效考核、设备管理、报表统计及输出、移动应用等功能，利用图像识别、大数据等各类技术和业务模型实现关键问题自主识别与预警，形成“基于数据的片区管理+站点无人值守”新模式。最终使得用户的运营体系更加高效、生产更加智能、管理更加精细、决策更加科学、服务更加聚焦，提高村污业务的核心竞争力和经济效益。
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="sub-block slider">
                    
                    <div class="wrapper w12">
                        <img src="@/assets/img/case/shanghaishuili.jpg" class="show">

                        <div class="poster"></div>
                        <div class="text-content">
                            <div class="title">
                                <img src="@/assets/img/logo/上海水利院.png"  class="w_120">
                                <span>上海水利院联合研发智慧防汛系统</span>
                            </div>
                            <div class="detail">
                                <p>我司与上海市水利工程设计研究院有限公司（以下简称“水利院”）达成战略合作，充分融合水利院先进的防汛业务理念与我司丰富的水利信息化开发能力与实践经验，联合研究开发【FPM®数字孪生智慧水利防汛管理孪生系统】，构建了防汛保障区多个数字孪生闸站，实现了在三维场景下一体化的业务流转，同时系统集成水利院流域水文水动力专业模型，帮助水利防汛“四预”更加科学精准。
                                    系统已经于2022年7月上线运营，为高标准防汛保障区上海某区域流域水利防汛指挥、泵闸运营管理、流域综合治理打造“一站式、一体化”的综合管理平台，实现资源共享、数据挖掘、决策支撑、虚实互馈，构建起新型水利数字孪生基础设施，创新防汛管理新模式。
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="sub-block slider">
                    
                    <div class="wrapper w12">
                        <img src="@/assets/img/case/shanghaichengtou.jpg"  class="show">

                        <div class="poster"></div>
                        <div class="text-content">
                            <div class="title">
                                <img src="@/assets/img/logo/上海城投.png" >
                                <span>上海城投临港智慧水厂</span>
                            </div>
                            <div class="detail">
                                <p>构建“BIM模型+控制模型+管理模型”驱动的全感知数字孪生水厂，并在此基础之上运用数据融合、三维仿真、机器学习、UWB毫米波定位等技术打造“设备+工艺+人员+事件”的全要素数字化水厂运营管理体系，使得数字孪生成为实体水厂的全维度映射，人事物实时同步“运行”。通过“模型智脑”实现设备故障预知、生产负荷预测、安全风险预警，指导水厂计划性运维、低碳化精准调控、按需生产，使得一线工作人员和各条线管理人员都能够基于数据和算法高效工作、科学决策，水厂能够不断积累、转化专属的数据资产和经验知识，不断寻优，持续升级。
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="sub-block slider">
                    
                    <div class="wrapper w12">
                        <img src="@/assets/img/case/pudong.jpg"  class="show">

                        <div class="poster"></div>
                        <div class="text-content">
                            <div class="title">
                                <img src="@/assets/img/logo/上海浦东水务.png"  >
                                <span>浦东水务集团海滨污水处理厂智慧水厂</span>
                            </div>
                            <div class="detail">
                                <p>随着浦东水务集团智慧水务建设逐步推进，我公司深入参与编制形成了《浦东水务智慧水务总体发展规划》并在此基础上，承担浦东水务集团海滨污水处理厂数字化升级一期、二期项目，构建智慧水厂体系。项目建立了一套物联网采集平台、基于大数据分析的智能加药系统、设备智能诊断系统及覆盖全流程的生产运营管理平台，实现污水处理成本节约、提升信息化管理水平、降低水厂运行风险，帮助海滨污水处理厂实现运营管理、智能管理的全新升级。
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="sub-block slider">
                    
                    <div class="wrapper w12">
                        <img src="@/assets/img/case/pudong.jpg"  class="show">

                        <div class="poster"></div>
                        <div class="text-content">
                            <div class="title">
                                <img src="@/assets/img/logo/上海浦东水务.png"  >
                                <span>浦东水务集团南汇北水厂数字孪生水厂</span>
                            </div>
                            <div class="detail">
                                <p>南汇北水厂数字孪生系统构建基于水厂BIM一体化智慧应用管理，数据融合、流程协同，直观查看设备、人员、工单运行情况。同时构建基于三维场景下的各项业务场景应用，包括应急模拟演练、智慧巡检、设备管理、管线管理、图档管理、能耗管理、安全隐患管理、智能安防等全方位三维场景应用，采用集团端-水厂端-移动端、B/S C/S双架构融合部署，打造水三维交互式漫游体验和视觉化的管理方式，为水厂精细化运行管理提供有力支撑，全面提升南汇北水厂运行管理效率和现代化水平。
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="sub-block slider">
                    
                    <div class="wrapper w12">
                        <img src="@/assets/img/case/weining.jpg"  class="show">

                        <div class="poster"></div>
                        <div class="text-content">
                            <div class="title">
                                <img src="@/assets/img/logo/logo_zhongxin.png"  class="w_90">
                                <span>携手中信，打造县域智慧城市新名片</span>
                            </div>
                            <div class="detail">
                                <p>威宁智慧城市项目可概括为“1+2+3+4+3X”，即：统一建设、两项基础设施、三项硬件基础支撑、四项平台支撑、三大领域智慧应用。项目总投资1个亿，致力于夯实威宁硬件基础，搭建起智慧威宁多层次、立体式的信息化架构，实现绿色、泛在、互联、开放、共享的设计理念，促进政府管理、产业转型和民生服务的长足发展。
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="sub-block slider">
                    
                    <div class="wrapper w12">
                        <img src="@/assets/img/case/kaili.jpg"  class="show">

                        <div class="poster"></div>
                        <div class="text-content">
                            <div class="title">
                                <img src="@/assets/img/logo/北控水务.png" >
                                <span>贵州凯里智慧供水</span>
                            </div>
                            <div class="detail">
                                <p>辅助客户理顺业务流程，建立数据标准，将供水运营线下能力转化线上工具。建设智慧供水系统，采用SaaS化部署方式，实现灵活配置、远程升级、快速推广应用。使得供水业务全面感知和可视化，资产清晰，人员轨迹、工作绩效、工单流转明晰，为产销差管理提供更多抓手。通过“线上工单过程透明，计件薪酬多劳多得”，帮助用户解决管网漏失问题。在“控漏”的同时关注“止损”，通过营销管理系统辅助用户对水表选型、水表安装、抄表质量、计量评估、用水稽查过程管理，针对性地解决计量问题。为民众提供多渠道缴费和主动及时的服务，不断提升客户满意度、改善政府营商环境。
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="sub-block slider">
                    
                    <div class="wrapper w12">
                        <img src="@/assets/img/case/heshan.jpg"  class="show">

                        <div class="poster"></div>
                        <div class="text-content">
                            <div class="title">
                                <img src="@/assets/img/logo/北控水务.png" >
                                <span>鹤山水环境二三维智慧管控</span>
                            </div>
                            <div class="detail">
                                <p>项目将鹤山市纳入河长制管理范围的45条河流、74宗水库、32宗重点山塘及相关管网、污水处理厂的基础数据、实时检测数据统一整合，构建空天地一体化的监测体系
                                    ，同时获取厂、网、河的实时运行信息。通过BIM+数字孪生技术对真实场景的精准还原，移动端、电脑端、大屏幕多屏联动，全面实现管理可视化。同时该项目创新性地无人机影像和高光谱监测，并利用三维模型表达水体各区间端的污染情况，所见即所得，一张图、多场景全景展现河长制建设成效。                                    
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="sub-block slider">
                    
                    <div class="wrapper w12">
                        <img src="@/assets/img/case/xianggang.jpg"  class="show">

                        <div class="poster"></div>
                        <div class="text-content">
                            <div class="title">
                                <img src="@/assets/img/logo/北控水务.png" >
                                <span>香港近海环卫数字化管理</span>
                            </div>
                            <div class="detail">
                                <p>项目以海洋垃圾收、运、处全业务链的数字化全生命周期管理为核心、构建一体化、标准化、可视化的
                                    陆海统筹、河湖联动一体化管控平台，针对于作业区域、收运船只、执行班组、位置轨迹、垃圾处置等业务进行数据监控、工单线上流转，
                                    同时政府部门紧密结合形成无缝对接，第一时间处置突发及市民上报事件，辅助香港近海环卫管理部门及运营机构业务监管及运营管理，海洋垃圾管理及相关作业船只的全过程的指挥调度，提高企业收运效率保障垃圾及时处置，推进
                                    绿色海洋垃圾治理新时代新步伐。                                    
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="sub-block slider">
                    
                    <div class="wrapper w12">
                        <img src="@/assets/img/case/jiangmen.jpg"  class="show">

                        <div class="poster"></div>
                        <div class="text-content">
                            <div class="title">
                                <img src="@/assets/img/logo/logo_zhky.png"  class="w_90">
                                <span>地下中微子探测仪建设全流程BIM服务</span>
                            </div>
                            <div class="detail">
                                <p>江门中微子探测实验是在地下700米深的实验厅内建造直径为41米的球形探测器，是目前世界上最大的中微子探测项目，由王贻芳院士牵头全球十多个国家参与联合研究，投资22个亿，工程涉及近二十个专业，其建造难度和所面临的挑战前所未有。云建标凭借优秀的技术能力和多年在BIM+领域的丰富应用经验，成为该项目的综合管控咨询服务单位，为建筑结构、机电、微电子安装提供高精度仿真模拟，并复用模型成果，在未来运维中实现管理维护可视化。                                    
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
    </div>
</template>
<script>

import label_n_icon_01 from '@/assets/img/pc/label_n_icon_01.png';
import label_n_icon_02 from '@/assets/img/pc/label_n_icon_02.png';
import label_n_icon_03 from '@/assets/img/pc/label_n_icon_03.png';
import label_n_icon_04 from '@/assets/img/pc/label_n_icon_04.png';
import label_n_icon_05 from '@/assets/img/pc/label_n_icon_05.png';
import label_n_icon_06 from '@/assets/img/pc/label_n_icon_06.png';
import label_s_icon_01 from '@/assets/img/pc/label_s_icon_01.png';
import label_s_icon_02 from '@/assets/img/pc/label_s_icon_02.png';
import label_s_icon_03 from '@/assets/img/pc/label_s_icon_03.png';
import label_s_icon_04 from '@/assets/img/pc/label_s_icon_04.png';
import label_s_icon_05 from '@/assets/img/pc/label_s_icon_05.png';
import label_s_icon_06 from '@/assets/img/pc/label_s_icon_06.png';

import { Mark } from './mark.js';
export default{
    data() {
      return {
        select_icon_01: false,
        select_icon_02: false,
        select_icon_03: false,
        select_icon_04: false,
        select_icon_05: false,
        select_icon_06: false,
        list : [
            ['新疆多厂集约化管理', '445px', '175px', [410, 271], [437, 201], [464, 201], label_n_icon_03, label_s_icon_03, 'icon_03', 'left bottom', 'notClickable'],
            ['环球影城智慧水务', '621px', '224px', [881, 352], [834, 251], [780, 251], label_n_icon_05, label_s_icon_05, 'icon_05', 'right bottom', 'clickable', 6],
            ['黑龙江方正智慧城市', '1092px', '189px', [1076, 229], [1086, 215], [1150, 215], label_n_icon_06, label_s_icon_06, 'icon_06', 'left bottom', 'clickable'],
            ['锦州多厂集约化管理', '1090px', '242px', [1070, 321], [1080, 268], [1140, 268], label_n_icon_03, label_s_icon_03, 'icon_03', 'left bottom', 'notClickable'],
            ['北京稻香湖（地下）智慧污水厂', '572px', '272px', [881, 352], [844, 298], [814, 298], label_n_icon_01, label_s_icon_01, 'icon_01', 'right bottom', 'clickable', 6],
            ['冬奥延庆赛区', '892px', '238px', [881, 352], [890, 264], [916, 264], label_n_icon_06, label_s_icon_06, 'icon_06', 'left bottom', 'clickable', 6],
            ['北控水务集团智慧水务', '895px', '280px', [881, 352], [890, 306], [916, 306], label_n_icon_02, label_s_icon_02, 'icon_02', 'left bottom', 'clickable'],
            ['内蒙古科源供水一体化', '502px', '319px', [744, 374], [724, 345], [704, 345], label_n_icon_04, label_s_icon_04, 'icon_04', 'right bottom', 'clickable'],
            ['秦皇岛厂网一体化', '923px', '330px', [915, 363], [925, 356], [955, 356], label_n_icon_03, label_s_icon_03, 'icon_03', 'left bottom', 'clickable'],
            ['银川数字孪生污水厂', '489px', '364px', [720, 402], [700, 390], [680, 390], label_n_icon_01, label_s_icon_01, 'icon_01', 'right bottom', 'clickable'],
            ['胶州多厂集约化管理', '966px', '419px', [909, 420], [930, 446], [985, 446], label_n_icon_03, label_s_icon_03, 'icon_03', 'left bottom', 'notClickable'],
            ['济南多厂集约化管理', '956px', '373px', [951, 404], [963, 399], [980, 399], label_n_icon_03, label_s_icon_03, 'icon_03', 'left bottom', 'notClickable'],
            ['西安多厂集约化管理', '539px', '414px', [768, 440], [768, 440], [703, 440], label_n_icon_03, label_s_icon_03, 'icon_03', 'right bottom', 'notClickable', 13],
            ['杨凌智慧管廊', '540px', '453px', [768, 440], [745, 479], [696, 479], label_n_icon_06, label_s_icon_06, 'icon_06', 'right bottom', 'clickable'],
            ['天津创业环保南部区多层级数字化运营', '908px', '462px', [893, 503], [910, 488], [932, 488], label_n_icon_02, label_s_icon_02, 'icon_02', 'left bottom', 'clickable'],
            ['绵阳塔子坝数字孪生污水厂', '373px', '497px', [643, 509], [619, 524], [589, 524], label_n_icon_01, label_s_icon_01, 'icon_01', 'right bottom', 'clickable'],
            ['四川眉山村镇污水智慧运营', '380px', '543px', [664, 537], [638, 570], [600, 570], label_n_icon_03, label_s_icon_03, 'icon_03', 'right bottom', 'clickable', 17],
            ['成都双流公兴（地下）智慧污水厂', '340px', '586px', [664, 537], [628, 612], [605, 612], label_n_icon_01, label_s_icon_01, 'icon_01', 'right bottom', 'notClickable'],
            ['昆山多厂集约化', '769px', '513px', [963, 505], [946, 540], [900, 540], label_n_icon_03, label_s_icon_03, 'icon_03', 'right bottom', 'notClickable', 22],
            ['上海泵闸署智慧防汛', '746px', '554px', [963, 505], [950, 581], [935, 581], label_n_icon_05, label_s_icon_05, 'icon_05', 'right bottom', 'clickable', 22],
            ['上海城投临港智慧水厂', '1009px', '510px', [963, 505], [1003, 537], [1028, 537], label_n_icon_01, label_s_icon_01, 'icon_01', 'left bottom', 'clickable', 22],
            ['浦东水务海滨数字污水厂', '1022px', '558px', [963, 505], [1023, 585], [1041, 585], label_n_icon_01, label_s_icon_01, 'icon_01', 'left bottom','clickable', 22],
            ['浦东水务智慧北水厂', '1034px', '605px', [963, 505], [1030, 632], [1052, 632], label_n_icon_01, label_s_icon_01, 'icon_01', 'left bottom', 'clickable'],
            ['贵州威宁智慧城市', '447px', '680px', [720, 585], [660, 707], [626, 707], label_n_icon_06, label_s_icon_06, 'icon_06', 'right bottom', 'clickable'],
            ['安宁多厂集约化管理', '420px', '633px', [634, 621], [620, 660], [600, 660], label_n_icon_03, label_s_icon_03, 'icon_03', 'right bottom', 'notClickable'],
            ['贵州凯里智慧供水', '431px', '725px', [741, 598], [651, 752], [610, 752], label_n_icon_04, label_s_icon_04, 'icon_04', 'right bottom', 'clickable'],
            ['南宁大沙田智慧供水', '764px', '601px', [762, 639], [770, 628], [783, 628], label_n_icon_04, label_s_icon_04, 'icon_04', 'left bottom', 'notClickable'],
            ['鹤山水环境二三维智慧管控', '873px', '656px', [848, 662], [868, 683], [892, 683], label_n_icon_05, label_s_icon_05, 'icon_05', 'left bottom', 'clickable', 29],
            ['香港近海环卫数字化管理', '883px', '700px', [848, 662], [880, 727], [902, 727], label_n_icon_05, label_s_icon_05, 'icon_05', 'left bottom','clickable', 29],
            ['鹤山村镇污水智慧运营', '903px', '750px', [848, 662], [870, 777], [922, 777], label_n_icon_04, label_s_icon_04, 'icon_04', 'left bottom', 'notClickable'],  
            ['中科院江门中微子探测器BIM孪生', '480px', '771px', [820, 665], [792, 798], [742, 798], label_n_icon_06, label_s_icon_06, 'icon_06', 'right bottom', 'clickable'],
        ],
        marks: [],
        showDetail: false
      }  
    },
    mounted() {
        this.marks = this.list.map(item => {
            const mark = new Mark(...item);
            mark.mount('.mapbox');
            return mark;
        });
        this.buttonHover();
        this.mapItemClick();
        // this.btnBackClick();
    },
    methods: {
        buttonHover() {
            const buttonAll = document.querySelectorAll('.button');
            Array.from(buttonAll).forEach(button => {
                button.addEventListener('mouseover', (e) => {
                    const value = e.target.getAttribute('value');
                    this[`select_${value}`] = !this[`select_${value}`];
                    const div = button.getElementsByTagName('div')[0];
                    div.style.color = '#FF8A00';
                    this.marks.filter(mark => mark.type === value).forEach(mark => {
                        mark.highlight('active');
                    })
                });
                button.addEventListener('mouseout', (e) => {
                    const value = e.target.getAttribute('value');
                    this[`select_${value}`] = !this[`select_${value}`];
                    const div = button.getElementsByTagName('div')[0];
                    div.style.color = '#FFFFFF';
                    this.marks.forEach(mark => {
                        mark.unhighlight('active');
                    })
                })
            })
        },
        mapItemClick() {
            const mapItemClickAll = document.querySelectorAll('.clickable');
            const sliderAll = document.querySelectorAll('.slider');
            Array.from(mapItemClickAll).forEach((item, index) => {
                item.addEventListener('click', () => {
                    console.log(sliderAll[index + 1]);
                    sliderAll[0].style.display = 'none';
                    sliderAll[index + 1].style.display = 'block';
                    this.$bus.$emit("back-show", true);
                    this.showDetail = true;
                    this.$bus.$on("back-click", () => {
                        this.showDetail = false;
                        sliderAll[0].style.display = 'block';
                        sliderAll[index + 1].style.display = 'none';
                    });
                })
            });
        }
    }
}
</script>
<style>
 .title4 {
    color: white;
    font-size: 32px;
    
    font-weight: lighter;
    margin-top: 42px;
}
.mapbox {
    width: 100%;
    background: url(@/assets/img/pc/map_bg.png) no-repeat;
    position: relative;
    height: 800px;
    margin-top: -78px;
}

.mapitem {
    display:flex;
    align-items: center;
    position: absolute;
    z-index: 99;
    cursor: pointer;
}

 .content .block .location .mapitem .imgright {
    background: url(@/assets/img/pc/label_normal_right.png) no-repeat;
    width: 40px;
    height: 29px;
    background-position: left 0px top -10px;
}

 .content .block .location .mapitem .imgright.active {
    background: url(@/assets/img/pc/label_selected_right.png) no-repeat;
    width: 40px;
    height: 29px;
    background-position: left 0px top -10px;
}

 .content .block .mapbox .mapitem .text-box {
    display: inline-block;
    font-size: 12px;
    
    font-weight: 400;
    color: #FFFFFF;
    text-shadow: 0px 2px 4px rgba(0,0,0,0.5);
    -webkit-background-clip: text;
    padding: 0 15px;
    background: url(@/assets/img/pc/label_normal_content.png) 50% 50% repeat-x;
    height: 29px;
    line-height: 29px;
}

 .content .block .mapbox .mapitem .text-box.active {
    text-shadow: 0px 0px 4px #FF8A00;
    background: url(@/assets/img/pc/label_selected_content.png) 50% 50% repeat-x;
}

 .content .block .mapbox .mapitem .top_line {
    width: 46px;
    height: 4px;
    position: absolute;
    top: -3px;
    left: 11px;
    background: url(@/assets/img/pc/label_normal_decorate.png) no-repeat;
}

 .content .block .mapbox .mapitem .bottom_line {
    width: 46px;
    height: 4px;
    position: absolute;
    right: 24px;
    bottom: 4px;
    background: url(@/assets/img/pc/label_normal_decorate.png) no-repeat;
}
 .content .block .mapbox .mapitem .top_line.active {
    background: url(@/assets/img/pc/label_selected_decorate.png) no-repeat;
}

 .content .block .mapbox .mapitem .bottom_line.active {
    background: url(@/assets/img/pc/label_selected_decorate.png) no-repeat;
}
 .content .block .mapbox svg {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 90;
}

 .content .block .mapbox .buttonbox {
    position: absolute;
    right: -12px;
    display: flex;
    justify-content: space-between;
    top: 140px;
    flex-direction: column;
    z-index: 999;
    height: 571px;
}
 .content .block .mapbox .buttonbox .button {
    width: 90px;
    text-align: center;
}

 .content .block .mapbox .buttonbox .label {
    font-size: 12px;
    color: #FFFFFF;
    
}
@media screen and (max-width: 1440px) {
     .content .block .mapbox .buttonbox .button {
        transform: scale(1.2);
    }
     .content .block .mapbox .mapitem {
        transform: scale(1.3);
        transform-origin: left bottom;
    }
     .content .block .mapbox {
        width: 133%;
        transform: scale(0.75);
        transform-origin: left top;
        /* transform: translate(-16%, -10%);; */
    }
}

.content .block {  
    height: 100%;
    width: 100%;
    margin: 0 auto;
    position: relative;
}

#Map {
    height: 100%;
}

.content {
    width: 97%;
    margin: 0 3% 0 0%;
    height: 100%;
}

/* .content .block .location {
    height: 800px;
    position: relative;
    margin-top: -70px;
} */

.content .block .location .site {
    width: 14px;
    height: 10px;
    background-color: #fff;
    border-radius: 50%;
    position: absolute;
}

.content .block .location .cityname {
    width: 60px;
    height: 20px;
    line-height: 14px;
}

.sub-block {
    height: 850px;
    width: 100%;
    height: 83%;
    position: relative;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-color: rgba(255, 255, 255, 0.1);
    display: none;
    margin-top: 76px;
}

.sub-block .wrapper img.show{
    position: absolute;
    width: 100%;
    height: 100%;
}

@keyframes pageUpEnter {
    0% {
        opacity: 0;
        top: 50%;
    }

    100% {
        opacity: 1;
        top: 0%;
    }
}

@keyframes pageDownExit {
    0% {
        opacity: 1;
        top: 0%;
    }

    100% {
        opacity: 0;
        top: 50%;
    }
}

@keyframes pageFadeExit {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

.sub-block .wrapper .text-content {
    position: absolute;
    height: auto;
    padding-bottom: 60px;
    width: 100%;
    bottom: 0px;
    background-color: rgba(0, 0, 0, 0.5);
}

.text-content .title {
    margin:20px 0 20px 40px;
    position: relative;
}

.text-content .title span {
    /* position: relative; */
    margin-left: 20px;
    /* display: inline-block; */
    font-size: 26px;
    color: #fff;
}

.text-content .title img {
    width: 200px;
    vertical-align: middle;
}
.wrapper .text-content .title .w_90{
    width: 90px;
    margin-bottom: 20px;
}
.wrapper .text-content .title .w_100 {
    width: 100px;
}
.wrapper .text-content .title .w_120 {
    width: 120px;
}

.text-content .detail p {
    font-size: 20px;
    color: #fff;
    line-height: 30px;
    padding: 0 60px;
}

.case_wrapper{
    color: #fff;
    text-align: center;
    height: 20vh;
    position: absolute;
    width: 100%;
    bottom: 3vh;
    z-index: 3;
}

.case_wrapper li{
    position: relative;
    width: 25%;
    float: left;
}

.case_wrapper li::before{
    position: absolute;
    content: '';
    z-index: 2;
    width: 60px;
    height: 1px;
    background: rgba(250,250,231,.5);
    left: 50%;
    top: 142px;
    transform: translateX(-50%);
}

.case_wrapper li::after{
    position: absolute;
    content: '';
    z-index: 2;
    width: 1px;
    height: 86px;
    left: 0;
    background: rgba(28,56,93,1);
    bottom: 76px;
}
.case_wrapper li:nth-child(1)::after{
    display: none;
}

.case_wrapper li h2{
    font-size: 28px;
    line-height: 64px;
}

.case_wrapper li p{
    font-size: 18px;
    line-height: 40px;
}

@media screen and (max-width: 1440px) {
    /* .content .block {
        transform:scale(0.7);
        margin-top: -2vh;
    } */
}

.content_app{
    padding-top: 40px;
    margin: 0 3%;
    width: 94% !important;
}
.title_nav{
    color: #fff;
    height:40px;
    line-height: 40px;
    font-size:14px;
    font-weight: 400;
}
.certification_wrap .slide_wrap{
    border: none;
}
p.name{
    opacity: .8;
    font-size: 12px;
    font-weight: normal;
    line-height: 30px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.desc{
    opacity: 0;
}
.certification_wrap .slide{
    margin-top: 0;
    height: 23vh;
}
.certification_wrap img{
    width: 100%!important;
    height: 100%;
}
.item_box{
    display: none;
}
.item_img{
    width: calc(100vw - 30px);
    text-align: center;
    margin-top: 40px;
}
.item_img img{
    max-width: 100%;
    max-height: 100%;
}
.text{
    height: 70vh;
    width: calc(100vw - 30px);
}

.item_title{
    color: #fff;
    font-size: 16px;
    line-height: 24px;
    padding: 20px 0 10px;
}
.item_desc{
    color: #fff;
    font-size: 14px;
    opacity: .6;
    line-height: 20px;
}

</style>
