<template>
    <div class="content">
        <div class="section_roadmap">
            <div class="road_title">
                成长历程
            </div>
            <div class="roadmap_wrap">
                <ul>
                    <li>
                        <div class="title">公司成立</div>
                        <p>中信国安+中国建筑标准院团队</p>
                        <div class="year">2015</div>
                    </li>
                    <li>
                        <div class="title">双高认证</div>
                        <p>国家、中关村高新企业认证</p>
                        <div class="year">2016</div>
                    </li>
                    <li>
                        <div class="title">北控水务战略入股</div>
                        <p>上海院士论坛首个推出数字孪生水厂
                            <br />
                            在鹤山实现流域级数字孪生</p>
                        <div class="year">2018</div>
                    </li>
                    <li>
                        <div class="title">加入中国水协智慧委</div>
                        <p>成为水协智慧委常委委员单位
                            <br />
                            掌握水务全业态核心产品</p>
                        <div class="year">2020</div>
                    </li>
                    <li>
                        <div class="title">对外服务</div>
                        <p>全面服务于行业市场</p>
                        <div class="year">2022</div>
                    </li>
                </ul>
            </div>
        </div>
        <div class="honor"> 
            <div class="road_title">
                荣誉资质
            </div>
            <SwiperBox
                class="swiperbox"
                :dataList="cardList"
                :swiperOption="swiperOption"
            >
                <template #default="data">
                    <div class="cardbox">
                        <div class="slide_wrap">
                            <img class="cardimg" :src="data.img" :alt="data.subTitle" />
                        </div>
                        <div class="cardText">{{ data.title }}</div>
                        <div class="cardText">{{ data.subTitle }}</div>
                    </div>
                </template>
            </SwiperBox>
        </div>
    </div>
</template>
<script>
    import SwiperBox from "@/components/SwiperBox";
    import certification1 from "@/assets/img/pc/certification/certification_01.png";
    import certification2 from "@/assets/img/pc/certification/certification_02.png";
    import certification3 from "@/assets/img/pc/certification/certification_03.png";
    import certification4 from "@/assets/img/pc/certification/certification_04.png";
    import certification5 from "@/assets/img/pc/certification/certification1.jpg";
    import certification6 from "@/assets/img/pc/certification/certification2.jpg";
    import certification7 from "@/assets/img/pc/certification/certification4.jpg";
    import certification8 from "@/assets/img/pc/certification/certification3.jpg";
    import certification9 from "@/assets/img/pc/certification/certification11.jpg";
    import certification10 from "@/assets/img/pc/certification/certification10.jpg";
    import certification11 from "@/assets/img/pc/certification/certification6.jpg";
    import certification12 from "@/assets/img/pc/certification/certification5.jpg";
    export default {
        components: {
            SwiperBox,
        },
        data() {
            return {
                swiperOption: {
                    slidesPerView: 5,
                    spaceBetween: 50,
                    loop: true,
                    autoplay: {
                    disableOnInteraction: false,
                    waitForTransition: false,
                    },
                },
                cardList: [
                    {
                        title:"国家级",
                        subTitle:'ITSS认证',
                        img: certification1,
                    },
                    {
                        title:"国家级",
                        subTitle:'电子智能化专业承包二级',
                        img: certification2,
                    },
                    {
                        title:"国家级",
                        subTitle:'ISO27001认证',
                        img: certification3,
                    },
                    {
                        title:"国家级",
                        subTitle:'中国水协团体会员',
                        img: certification4,
                    },
                    {
                        title:"国家级",
                        subTitle:'高新技术企业',
                        img: certification5,
                    },
                    {
                        title:"北京市",
                        subTitle:'高新技术企业',
                        img: certification6,
                    },
                    {
                        title:"国家级",
                        subTitle:'软件企业认证',
                        img: certification7,
                    },
                    {
                        title:"北京市",
                        subTitle:'软件企业认证',
                        img: certification8,
                    },
                    {
                        title:"国家级",
                        subTitle:'ISO9001认证',
                        img: certification9,
                    },
                    {
                        title:"国家级",
                        subTitle:'中国水协智慧委委员单位',
                        img: certification10,
                    },
                    {
                        title:"北京市",
                        subTitle:'诚信系统集成企业',
                        img: certification11,
                    },
                    {
                        title:"国家级",
                        subTitle:'20+项软件著作权证书',
                        img: certification12,
                    },


                ]
            }
        },
        methods: {

        }
    }
    
</script>
<style lang="less" scoped>
.content {
    height: 100vh;
    padding-right: 5%;
    display: flex;
    flex-direction: column;
    .section_roadmap{
        width: 100%;
        height: 46vh;
        margin: 60px auto 0;
        .roadmap_wrap{
            width: 100%;
            color: #fff;
            padding-top: 20px;
            .title{
                font-size: 18px;
                padding-left: 20px;
                background: url("@/assets/img/pc/companyHistory/arrow_road.png") no-repeat left center;
                white-space: nowrap;
            }
            ul{
                display: flex;
                position: relative;
                width: 96%;
            }
            ul::after{
                position: absolute;
                left: 0;
                top: 100%;
                z-index: 2;
                content: '';
                background: #388CFF;
                width: 104.2%;
                height: 2px;
            }
            li{
                width: calc(100% / 6);
                background: url("@/assets/img/pc/companyHistory/up.png") no-repeat left bottom;
                background-size: 100% 152%;
                padding-left: 1em;
                padding-bottom: 5em;
                position: relative;
                list-style: none;
                z-index: 3;
            }
            li::after{
                position: absolute;
                left: calc(100% - 8px);
                content: '';
                z-index: 3;
                background: red;
                width: 8px;
                height: 8px;
                border-radius: 50%;
                border: 5px solid #fff;
                bottom: -9px;
            }
            li .year{
                position: absolute;
                left: calc(100% - 16px);
                z-index: 3;
                bottom: -34px;
            }
            li:nth-child(2n) .year{
                position: absolute;
                left: calc(100% - 16px);
                z-index: 3;
                /* background: red; */
                /* width: 8px; */
                /* height: 8px; */
                /* border-radius: 50%; */
                /* border: 5px solid #fff; */
                /* bottom: -9px; */
                top: -34px;
            }
            li p{
                line-height: 20px;
                width: 146%;
                font-size: 14px;
            }
            li:nth-child(2n){
                transform: translateY(100%);
                background:url("@/assets/img/pc/companyHistory/down.png") no-repeat left top;
                padding-bottom: 0;
                padding-top: 5em;
                background-size: 100% 152%;
            }
            li:nth-child(2n)::after{
                top: -9px;
            }
        }
    } 
    .honor {
        margin-top: 30px;
        height: 40%;
        margin-bottom: 20px;
        .swiperbox {    
            .cardbox { 
                margin-top: 30px;
                font-size: 16px;
                line-height: 25px;
                color: #fff;
                .slide_wrap {
                    position: relative;
                    height: 18vh;
                    border: 1px solid white;
                    margin-bottom: 10px;
                    overflow: hidden;
                    img {
                        width: 75%;
                        height: 75%;
                        position: absolute;
                        left:50%;
                        top: 50%;
                        transform: translate(-50%,-50%);                        
                    }
                }

                .cardText {      
                    line-height: 25px;
                    text-align: center;
                }
            }
        }
    } 
}
.road_title {
    color: white;
    font-size: 32px;            
    font-weight: lighter;
}
</style>