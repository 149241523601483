import Vue from 'vue'
import VueRouter from 'vue-router'

import HomeView from '@/views/HomeView.vue'
import MobileView from "@/views/mobile_view/index.vue";
import PcView from "@/views/pc_view/index.vue";
import RecruitView from "@/views/RecruitView.vue";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/pc',
    name: 'pc',
    component: PcView,
    meta: {keepAlive: true} //true缓存 false不缓存
  },
  {
    path: '/mobile',
    name: 'mobile',
    component: MobileView,
    meta: {keepAlive: true} //true缓存 false不缓存
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/recruit',
    name: 'recruit',
    component: RecruitView
  },
]

const router = new VueRouter({
  routes
})

export default router
