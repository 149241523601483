<template>
  <div class="page3box">
    <div class="recruit1">
        <div class="">加入我们</div>
        <div class="jobs ">
          <span @click="goDetail(0)">区域销售经理</span>
          <span @click="goDetail(1)">行业销售总监</span>
          <span @click="goDetail(2)">售前咨询</span>
          <span @click="goDetail(3)">产品经理</span>
          <span @click="goDetail(4)">网络实施工程师</span>
          <span @click="goDetail(7)">更多</span>
        </div>
    </div>

    <div class="contact_wrapper">
      <div class="">联系我们</div>
      <div class="qrcode_wrap">
        <div>
          <img src="@/assets/img/logo/qrcode.png" >
          <div class="sub_title">企业公众号</div>
        </div>
        <div>
          <img src="@/assets/img/pc/qrcode_sale.png" >
          <div class="sub_title">企业销售</div>
        </div>
      </div>
      <div class="contact">
          <span>
              地址：北京市东城区东直门外大街46号天恒大厦B座16层
          </span>
          <br>
          <span>
              邮编：100027
          </span>
          <br>
          <span>
              邮箱：service@cyberwater.cn
          </span>
          <br>
          <span>
            备案号：<a href="http://beian.miit.gov.cn" style="color: #fff;" target="new">京ICP备20010617号</a>
          </span>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  methods: {
    skipPage(index){
      this.$bus.$emit('page-slide-to', index);
    },
    goDetail(index){
      this.$router.push({name:"recruit",params:{curId:index}});
    }
  },
};
</script>

<style lang="less" scoped>
.page3box {
  color: #fff;
  padding: 0 7%;
  display: flex;
  flex-direction: column;
  .recruit1 {
    margin-top: 15px;
    color: white;
    font-size: 28px;
    font-family: Microsoft JhengHei;
    font-weight: lighter;
    line-height: 70px;
    .jobs{
      margin-top: 50px;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      width: 100%;
      line-height: 34px;
      span{
        font-size: 16px;
        margin-right: 6px;
        padding: 0 6px;
        color: #fff;
        line-height: 28px;
        display: inline;
        margin: 4px;
        border: 1px solid white;
        // text-decoration: none;
      }
    }
  }
  .contact_wrapper{
      display: flex;
      flex-direction: column;
      color: white;
      font-size: 28px;
      font-family: Microsoft JhengHei;
      font-weight: lighter;
      margin-top: 30px;
      .qrcode_wrap {      
          font-size: 12px;
          display: flex;
          justify-content: space-around;
          align-items: center;
          margin: 20px 0;
          img{
            width: 80px;
            margin-bottom: 2px;
          }
          .sub_title {
            text-align: center;
          }
      }
      .contact {
        display: flex;
        flex-direction: column;
        font-size: 12px;
        margin: 20px 0;
        line-height: 18px;
        
      }
    }
}
</style>