<template>
  <div class="page2box">
    <Transition name="fade">
      <div key="pagemain" v-if="!isShowDetail" class="pagemain">
        <div class="title">解决方案</div>
        <div class="content">
          <div
            class="carditem"
            v-for="(item, index) in cards"
            :key="index"
            @click="showDetail(item)"
          >
            <img :src="item.img" :alt="item.title" />
            <div class="cardtext">
              <p>{{ item.title }}</p>
              <p class="en">{{ item.subtitle }}</p>
            </div>
          </div>
        </div>
        
      </div>
      <div key="pagedetail" v-if="isShowDetail" class="pagedetail">
        <div class="back" @click="goback"></div>
        <div class="left">
          <div>
            <p class="detailtitle">{{ detailInfo.title }}</p>
            <p class="detailsubtitle">{{ detailInfo.subtitle }}</p>         
            <p class="detaildesc">{{ detailInfo.desc }}</p>
          </div>
        </div>
        <div class="right">
          <div>
            <div class="detailtags">
              <div
                class="tagbtn"
                :class="{ active: detailTag === tag }"
                v-for="(tag, index) in detailInfo.tags"
                :key="index"
                @click="changeTags(tag)"
              >
                {{ tag.name }}
              </div>
            </div>
            <div class="taglist">
              <div
                class="tagitem"
                v-for="(item, index) in detailTag.list"
                :key="index"
              >
                <img class="tagicon" :src="item.icon" alt="" />
                <div class="tagdesc">
                  <p class="title">{{ item.short }}</p>
                  <p class="content">{{ item.long }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </Transition>
  </div>
</template>

<script>
import img1 from "@/assets/img/pc/水务集团数字化运营.png";
import img2 from "@/assets/img/pc/流域一体化业务管理.png";
import img3 from "@/assets/img/pc/BIM+数字孪生.png";

import icon11 from "@/assets/icon/1-1.png";
import icon12 from "@/assets/icon/1-2.png";
import icon13 from "@/assets/icon/1-3.png";
import icon14 from "@/assets/icon/1-4.png";

import icon21 from "@/assets/icon/2-1.png";
import icon22 from "@/assets/icon/2-2.png";
import icon23 from "@/assets/icon/2-3.png";
import icon24 from "@/assets/icon/2-4.png";

import icon31 from "@/assets/icon/3-1.png";
import icon32 from "@/assets/icon/3-2.png";
import icon33 from "@/assets/icon/3-3.png";
import icon34 from "@/assets/icon/3-4.png";

import icon81 from "@/assets/icon/8-1.png";
import icon82 from "@/assets/icon/8-2.png";
import icon83 from "@/assets/icon/8-3.png";
import icon84 from "@/assets/icon/8-4.png";

import icon41 from "@/assets/icon/4-1.png";
import icon42 from "@/assets/icon/4-2.png";
import icon43 from "@/assets/icon/4-3.png";
import icon44 from "@/assets/icon/4-4.png";

import icon91 from "@/assets/icon/9-1.png";
import icon92 from "@/assets/icon/9-2.png";
import icon93 from "@/assets/icon/9-3.png";
import icon94 from "@/assets/icon/9-4.png";

import icon51 from "@/assets/icon/5-1.png";
import icon52 from "@/assets/icon/5-2.png";
import icon53 from "@/assets/icon/5-3.png";
import icon54 from "@/assets/icon/5-4.png";

export default {
  data() {
    return {
      isShowDetail: false,
      cards: [
        {
          img: img1,
          title: "水务集团数字化运营",
          subtitle: "DIGITAL OPERATION OF WATER GROUP",
          desc: "打造贯穿水务集团决策层-管理层-执行层的一体化经营、运营管理和决策分析平台，灵活适配单厂、多厂、分布式厂站等多个业务场景和多种业态，实现数据资产化、业务管理精细化、决策科学化，全面提升企业管理水平与执行效率。",
          tags: [
            {
              name: "执行层",
              list: [
                {
                  icon: icon11,
                  short: "数据可视，报表灵活",
                  long: "灵活定制各类运营管理专题，形成“千人千面”交互式大数据智能图表，跨维度全景展现历史规律，把握运行态势。",
                },
                {
                  icon: icon12,
                  short: "人事物在线，闭环留痕",
                  long: "所有工单在线管理、跨系统流动、管控透明，管理模型驱动规定动作的严格执行，绩效模型助力执行质量的量化分析。",
                },
                {
                  icon: icon13,
                  short: "人机协同，提升效率",
                  long: "打通水厂自控、安防、巡检等多个系统，高效人机协同解决一线痛点，降低工作负荷，提升员工执行效率和获得感。",
                },
                {
                  icon: icon14,
                  short: "智能控制，绿色高效",
                  long: "AI+数据训练本厂专属模型，根据实时数据动态调节工艺，实现水厂按需加药、按需曝气、按需加压，节约能耗、药耗。",
                },
              ],
            },
            {
              name: "管理层",
              list: [
                {
                  icon: icon21,
                  short: "灵活适配管理需求变化",
                  long: "根据实际情况与需求，灵活匹配管理模式、信息化工具及技术赋能方式，实现多种层级式管理的扁平化、集约化的管理目标。",
                },
                {
                  icon: icon22,
                  short: "区域资源深度共享",
                  long: "在集群管理模式下，区域内人员、技术、服务等资源统一调配、深度共享，使各项目日常运营协调一致，节约整体管理成本。",
                },
                {
                  icon: icon23,
                  short: "助力组织结构优化",
                  long: "打造集约化、规范化的工作流程与管理模式，提升人员技能与管理水平，实现人才结构和组织结构优化与升级。",
                },
                {
                  icon: icon24,
                  short: "支持业务规模快速扩展",
                  long: "平台采用SaaS化架构、低代码部署，可灵活接入增量项目，快速低成本纳入整体管理体系，避免重复建设和运维。",
                },
              ],
            },
            {
              name: "决策层",
              list: [
                {
                  icon: icon31,
                  short: "统一标准，数据驱动",
                  long: "通过统一的信息管理标准、业务流程标准，实现运营数据一体化，保障数据汇聚整合畅通，充分发挥数据资产价值。",
                },
                {
                  icon: icon32,
                  short: "主题丰富，全面监管",
                  long: "经营管理全维度、多主题数据审计、分析，上级公司可对下辖单位开展预算、质量、资产、生产等专项监管和督导。",
                },
                {
                  icon: icon33,
                  short: "科学考核，精准决策",
                  long: "基于各公司运营管理指标体系定制，基于数据自动评价生产经营成效，形成依托数据的管理抓手。",
                },
                {
                  icon: icon34,
                  short: "能力提升，智慧转型",
                  long: "助力形成数据驱动的管理决策机制，逐步实现数字化、智慧化转型，塑造差异化运营能力。",
                },
              ],
            },
          ],
        },
        {
          img: img2,
          title: "一体化业务管理",
          subtitle: "INTEGRATED BUSINESS MANAGEMENT",
          desc: "面向涉水业务的一体化运管和监管需求，建设多维度感知体系、融合多部门数据、构建水专业模型，打造场景化应用，通过“业务协同+流程塑造+技术创新”组合拳，推动水业监管、运营向一体化转变。",
          tags: [
            {
              name: "厂网一体化",
              list: [
                {
                  icon: icon81,
                  short: "智能预测，协同响应",
                  long: "针对城市内涝、污水溢流、管道淤堵、负荷冲击等关键问题实现智能预测和主动告警，使监管部门与运管单位有效协同，及时响应。",
                },
                {
                  icon: icon82,
                  short: "厂网联动，防涝减灾",
                  long: "充分激活排水体系的输送能力和调蓄能力，科学调配水量，降低城市内涝风险与水厂进水负荷冲击，保障生命财产安全。",
                },
                {
                  icon: icon83,
                  short: "智能养护，管理精细",
                  long: "实现设备设施的全生命周期数字化管理，沉淀运营过程数据，并结合管理模型实现对人事物的科学评价与人员激励。",
                },
                {
                  icon: icon84,
                  short: "模式变革，成本最优",
                  long: "基于系统思维的厂站网集约化运营和一体化调控，实现跨部门资源共享和作业协同，实现运营总成本的最优化。",
                },
              ],
            },
            {
              name: "城乡供水一体化",
              list: [
                {
                  icon: icon81,
                  short: "管理协同",
                  long: "建立从源头到龙头的全域感知，打通各环节数据和流程交互，实现跨业务、跨层级协同联动，优化整体运营效率。",
                },
                {
                  icon: icon82,
                  short: "降本增效",
                  long: "降低管网漏损率和产销差，提高设备设施完好率，延长资产的升级改造周期，降低运营成本，提高企业效益。",
                },
                {
                  icon: icon83,
                  short: "服务敏捷",
                  long: "为用户提供便捷的服务平台、丰富的缴费渠道和快速的服务响应，实现用户最多跑一次营业大厅，优化营商环境。",
                },
                {
                  icon: icon84,
                  short: "安全提升",
                  long: "对供水系统开展在线动态分析和数字化精细运维，严控水质污染风险、减少爆管事故、停机事故，提升安全保障能力。",
                },
              ],
            },
          ],
        },
        {
          img: img3,
          title: "BIM+数字孪生",
          subtitle: "BIM+DIGITAL TWIN",
          desc: "运用BIM/CIM等数字化技术，将物理世界的各种属性全要素还原，通过“机理算法+真实运行数据+AI模型”融合仿真模拟手段，实现对水厂调度、流域调度、防汛指挥等业务场景描述、诊断、预测、推演，物理空间与赛博空间交互协同，催生新型智慧决策机制。",
          tags: [
            {
              name: "孪生水厂",
              list: [
                {
                  icon: icon41,
                  short: "打造新型可视基础设施",
                  long: "高精度BIM模型1:1还原水厂，实现设计、建设期成果数字化传承，形成新型运营基础设施，信息无损传递，所见即所得。",
                },
                {
                  icon: icon42,
                  short: "数据驱动，虚实互馈",
                  long: "支持机理、AI仿真算法嵌入，基于高仿真视觉通道进行数据分析，模拟推演，指导现实作业，赋予模型，场景以生命。",
                },
                {
                  icon: icon43,
                  short: "精细化运维，智慧生产",
                  long: "三维可视化运维辅助交互工具，扩展数据空间属性，端子级呈现，透视隐蔽工程，辅助运维排障高效化、精细化。",
                },
                {
                  icon: icon44,
                  short: "灵活交互，生动表达",
                  long: "灵活基于现场大屏幕尺寸，快速修改配适，中控室升级为面向未来的“水厂大脑”，直观展现水厂建设运营成效。",
                },
              ],
            },
            {
              name: "水环境数字孪生",
              list: [
                {
                  icon: icon91,
                  short: "新型感知，低耗运维",
                  long: "采用全光谱、微试剂、无人机、遥感、视频智能识别等新一代物联感知技术，扩展监测广度深度，低人力、药剂消耗，运维成本低。",
                },
                {
                  icon: icon92,
                  short: "精细可视化管理",
                  long: "基于BIM+GIS二三维一张图，实现厂网河湖孪生体构建与展现，融合各类数据，跨维度、多角度关联分析，挖掘规律。",
                },
                {
                  icon: icon93,
                  short: "污染溯源，加强监管",
                  long: "构建区域专有水质指纹库+网格化管理体系，快速逐级追溯污染源，增强水政执法力度。",
                },
                {
                  icon: icon94,
                  short: "统筹调度，生态保障",
                  long: "在全面感知基础上建立模型驱动的活水调度能力，全局统筹，灵活调整调度策略，优化水系生态环境。",
                },
              ],
            },
            {
              name: "水利数字孪生",
              list: [
                {
                  icon: icon51,
                  short: "工程安全",
                  long: "建立精细化运营管理体系，运用先进的信息技术及分析手段，加强水利工程设施设备的智能化管控，使工程运行安全可靠。",
                },
                {
                  icon: icon52,
                  short: "调配科学",
                  long: "强化用水统计与分析，通过取用水监管、用水量预测、生态补水评估等手段，结合专家经验库、历史场景模式库，支撑水资源科学调配。",
                },
                {
                  icon: icon53,
                  short: "防洪排涝",
                  long: "基于水文、水力模型强化水系洪涝事件的预报、预警、预演、预案能力，结合远程控制和科学调度实现防涝减灾。",
                },
                {
                  icon: icon54,
                  short: "幸福河湖",
                  long: "提供丰富的公众参与渠道，利用新一代虚实融合技术为民众提供水安全、水文化、水景观的生动体验，并及时获取公众投诉建议。",
                },
              ],
            },
          ],
        },
      ],
      detailInfo: {},
      detailTag: null,
    };
  },
  created() {
    this.$bus.$on("page-slide-change", (index) => {
      if(index !== 2 && this.isShowDetail){
        this.isShowDetail = false;
      }
    });
  },

  methods: {
    showDetail(item) {
      this.isShowDetail = true;
      this.detailInfo = item;
      this.detailTag = item.tags[0];
    },
    goback() {
      this.isShowDetail = false;
    },
    changeTags(tag) {
      this.detailTag = tag;
    },
  },
};
</script>

<style lang="less" scoped>
.page2box {
  color: #fff;
  padding-right: 5%;
  .pagemain {
    display: flex;
    flex-direction: column;  
    .title {
      color: white;
      font-size: 32px;
      height: 88px;
      line-height: 88px;
      font-weight: lighter;
      margin-top: 50px;
    }
    .content {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
      .carditem {
        flex: 1;
        width: 29.5%;
        height: 80vh;
        overflow:hidden;
        cursor: pointer;

        &:first-child {
          margin-left: 0;
        }
        position: relative;
        margin-left: 10px;
        img {
          width: 130%;                   
          height: 100%;
          object-fit: fill;
          position: absolute;
          left: 0;
          transition: left 3s ease,box-shadow 3s ease;
        }
        img:hover {
          left: -30%;
        }
        .cardtext {
          width: 100%;
          position: absolute;
          left: 50%;
          bottom: 25%;
          transform: translateX(-50%);
          font-size: 20px;
          text-align: center;
          .en {
            font-size: 14px;
            margin-top: 7px;
          }
        }
      }
    }
  }
  .pagedetail {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    .left {
      display: flex;
      width: 40vw;
      height: 100vh;      
      div {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-self:center;
        .detailtitle {
          margin-top: 10px;
          font-size: 36px;
        }
        .detailsubtitle {
          margin-top: 10px;
          font-size: 16px;
          height: 32px;
          line-height: 32px;
          margin-bottom: 35px;
        }
        .detaildesc {
          font-size: 18px;
          line-height: 32px;
        }
      }
    }

    .right {
      display: flex;
      flex: 1;
      margin-left: 30px;
      div {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-self: center;
        .detailtags {
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          justify-content: space-around;
          font-size: 24px;
          width: 52vw;
          .tagbtn {
            margin-right: 20px;
            padding-bottom: 5px;
            cursor: pointer;
            &:last-child {
              margin-right: 0;
            }
            &.active {
              font-weight: bolder;
              border-bottom: 1px solid #fff;
            }
          }
        }
        .taglist {
          margin-top: 60px;
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          
          .tagitem {
            width: calc(50% - 30px);
            margin-right: 10px;
            margin-bottom: 10px;
            padding: 5px 10px;
            border: 1px solid #eee;
            display: flex;
            flex-direction: row;
            align-items: center;
            height: 165px;

            .tagicon {
              width: 70px;
              height: 70px;
            }
            .tagdesc {
              margin-left: 10px;
              align-self: flex-start;
              .title {
                font-size: 20px;
              }
              .content {
                margin-top: 20px;
                font-size: 14px;
                line-height: 26px;
              }
            }
          }
          .tagitem:nth-child(2n){
            margin-right: 0px;
          }
        }
      }
    }
  }
}



</style>
