<script>
export default {
    props: {
        dataList: Array,
        swiperOption: Object,
    },
  render() {
    const list = this.$props.dataList;
    const swiperOption = this.$props.swiperOption || {};
    let lock = false
    function handleTap(event) {
      if (lock) return;
      lock = true;
      setTimeout(() => {
        lock = false;
      }, 200);
      this.$emit('on-tap', this.$refs.refWiper.swiper, event);
    }
    return (
      <swiper ref="refWiper" options={swiperOption} onTap={handleTap.bind(this)}>
        {list.map((data) => (
          <swiper-slide>{this.$scopedSlots.default(data)}</swiper-slide>
        ))}
      </swiper>
    );
  },
};
</script>
<style lang="scss" scoped></style>
