<template>
  <div v-if="showLog" class="testbox">
    <p>innerHeight: {{ innerHeight }}</p>
    <p>innerWidth: {{ innerWidth }}</p>
  </div>
</template>
<script>
export default {
  data() {
    return {
      showLog: process.env.NODE_ENV === "development",
      innerWidth : window.innerWidth,
      innerHeight : window.innerHeight,
    }
  },
  created() {
    // 初始计算
    this.calculateVh();
    // 调整大小时重新计算
    window.addEventListener("resize", this.calculateVh);
    // 在设备方向改变时重新计算
    window.addEventListener("orientationchange", this.calculateVh);
  },
  methods: {
    calculateVh() {
      // 以像素为单位计算1vh值
      // 基于窗口的内部高度
      var vh = window.innerHeight * 0.01;

      //  将CSS变量设置为根元素
      // 相当于1vh
      document.documentElement.style.setProperty("--vh", vh + "px");
    },
  },
};
</script>
<style lang="less" scoped>
.testbox {
  position: fixed;
  top: 0;
  left: 0;
  background-color: black;
  color: white;
  padding: 10px;
  z-index: 666;
  user-select: none;
  opacity: 0.2;
}
</style>