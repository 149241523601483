<template>
    <div class="section6">
            <div class="content section_roadmap_phone">
                <div class="block">
                    <div class="road_title">
                        成长历程
                    </div>
                    <div class="roadmap_wrap">
                        <ul>
                            <li>
                                <div class="title">公司成立</div>
                                <p>中信国安+中国建筑标准院团队</p>
                                <div class="year">2015</div>
                            </li>
                            <li>
                                <div class="title">双高认证</div>
                                <p>国家、中关村高新企业认证</p>
                                <div class="year">2016</div>
                            </li>
                            <li>
                                <div class="title">北控水务战略入股</div>
                                <p>上海院士论坛首个推出数字孪生水厂在鹤山实现流域级数字孪生</p>
                                <div class="year">2018</div>
                            </li>
                            <li>
                                <div class="title">加入中国水协智慧委</div>
                                <p>成为水协智慧委常委委员单位掌握水务全业态核心产品</p>
                                <div class="year">2020</div>
                            </li>
                            <li>
                                <div class="title">对外服务</div>
                                <p>全面服务于行业市场</p>
                                <div class="year">2022</div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
</template>
<script>
    export default {
        data() {
            return {

            }
        },
        methods: {

        }
    }
</script>
<style scoped>
.section6 {
    height: 100%;
}
.section6 .section_roadmap_phone{
    display: block;
    width: 94%;
    margin: 0 3%;
    height: 100%;
}
.section6 .content .block {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.section6 .content .block .road_title {
    color: #fff;
    font-size: 28px;
    font-family: Microsoft JhengHei;
    font-weight: lighter;
    margin: 30px 0 50px 0;
}
.roadmap_wrap{
    width: 100%;
    color: #fff;
    flex: 1;
}
.section6 .roadmap_wrap ul{
    width: 100%;
    height: calc(100% - 42px);
    display: block;
    position: relative;
}

.section6 .roadmap_wrap ul::after{
    left: 50%;
    top: -50px;
    height: 100%;
    width: 2px;
    position: absolute;
    z-index: 2;
    content: '';
    background: #388CFF;
}
.section6 .roadmap_wrap li{
    width: 50%;
    background:url(../../../assets/img/left.png) no-repeat right bottom;
    height: calc(20% - 10px);
    background-size: contain;
    position: relative;
    z-index: 3;
    list-style: none;
}
.roadmap_wrap li::after{
    position: absolute;
    left: calc(100% - 8px);
    content: '';
    z-index: 3;
    background: red;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    border: 5px solid #fff;
    bottom: -9px;
    top: -9px;
}
.section6 .roadmap_wrap .title{
    font-size: 14px;
    background: none;
    padding: 0;
    white-space: nowrap;
}
.section6 .roadmap_wrap ul li p{
    margin-top: 4px;
    line-height: 20px;
    font-size: 14px;
    padding-right: 40px;
}
.section6 .roadmap_wrap li .year{
    top: -11px;
    left: calc(100% + 16px);
    position: absolute;
    z-index: 3;
    bottom: -34px;
}
.roadmap_wrap li:nth-child(2n) .year{
    position: absolute;
    left: calc(100% - 16px);
    z-index: 3;
    top: -34px;
}
.roadmap_wrap li:nth-child(2n){
    transform: translateY(100%);
    background: url(../../../assets/img/down.png) no-repeat left top;
    padding-bottom: 0;
    background-size: 100% 152%;
}
.roadmap_wrap li:nth-child(2n)::after{
    top: -9px;
}
    .section6 .roadmap_wrap li:nth-child(2n)::after{
        left: -8px;
    }
    .section6 .roadmap_wrap li:nth-child(2n) .year{
        left: -52px;
        top: -11px;
    }
    .section6 .roadmap_wrap li:nth-child(2n){
        background: url(../../../assets/img/right.png) no-repeat left bottom;
        margin-left: 50%;
        background-size: contain;
        transform: translateY(0);
        padding: 0 10px 0 40px;
    }
    .section6 .roadmap_wrap li:nth-child(2n+1) .title{
        text-align: right;
        padding: 0 40px 0 10px;
    }

</style>