<template>
  <div class="page3box">
    <Transition :name="isShowDetail ? 'slideleft' : 'slideright'">
      <div key="pagemain" v-if="!isShowDetail" class="pagemain">
        <div
          class="carditem"
          v-for="(item, index) in cards"
          :key="index"
          @click="showDetail(index)"
        >
          <img class="mainimg" :src="item.img" :alt="item.title" />
          <div class="cardtext">
            <p>{{ item.title }}</p>
            <p class="en">{{ item.subtitle }}</p>
          </div>
        </div>
      </div>
      <div key="pagedetail" v-if="isShowDetail" class="pagedetail">
        <div v-if="detailIndex === 0" class="zhinengganzhi">
          <SwiperBox
            class="swiperbox"
            v-for="(row, index) in zhinengganzhiRows"
            :key="index"
            :dataList="row"
            :swiperOption="getSwiperOption({ reverse: index % 2, width: 200 })"
            @on-tap="onTap"
          >
            <template #default="data">
              <SlideCard v-bind="data" :width="200" :height="250" :rows="2"></SlideCard>
            </template>
          </SwiperBox>
        </div>
        <div v-if="detailIndex === 1" class="zhihuiyingyong">
          <SwiperBox
            class="swiperbox"
            v-for="(row, index) in zhihuiyingyongRows"
            :key="index"
            :dataList="row"
            :swiperOption="getSwiperOption({ reverse: index % 2, width: 300 })"
            @on-tap="onTap"
          >
            <template #default="data">
              <SlideCard v-bind="data" :width="300" :height="170"  :rows="3"></SlideCard>
            </template>
          </SwiperBox>
        </div>
      </div>
    </Transition>
  </div>
</template>

<script>
import SwiperBox from "@/components/SwiperBox";
import SlideCard from "./SlideCard";
import zhinengganzhi from "@/assets/img/zhinengganzhi.png";
import zhinengyingyong from "@/assets/img/zhinengyingyong.png";
import app_智能应用_集团运营管理系统 from "@/assets/img/zhinengyingyong/app_智能应用_集团运营管理系统.jpg";
import app_智能应用_三维数字孪生水厂 from "@/assets/img/zhinengyingyong/app_智能应用_三维数字孪生水厂.jpg";
import app_智能应用_厂网一体化运营系统 from "@/assets/img/zhinengyingyong/app_智能应用_厂网一体化运营系统.jpg";
import app_智能应用_水环境流域孪生 from "@/assets/img/zhinengyingyong/app_智能应用_水环境流域孪生.jpg";
import app_智能应用_智慧水利管理系统 from "@/assets/img/zhinengyingyong/app_智能应用_智慧水利管理系统.jpg";
import app_智能应用_水害防御应急系统 from "@/assets/img/zhinengyingyong/app_智能应用_水害防御应急系统.jpg";
import app_智能应用_数据采集共享底座 from "@/assets/img/zhinengyingyong/app_智能应用_数据采集共享底座.jpg";
import app_智能应用_二三维空间融合引擎 from "@/assets/img/zhinengyingyong/app_智能应用_二三维空间融合引擎.jpg";
import app_智能应用_报表引擎 from "@/assets/img/zhinengyingyong/app_智能应用_报表引擎.jpg";
import app_智能感知_光谱法水质监测仪 from "@/assets/img/zhinengganzhi/app_智能感知_光谱法水质监测仪.jpg";
import app_智能感知_便携式水质分析仪 from "@/assets/img/zhinengganzhi/app_智能感知_便携式水质分析仪.jpg";
import app_智能感知_微试剂水质监测仪 from "@/assets/img/zhinengganzhi/app_智能感知_微试剂水质监测仪.jpg";
import app_智能感知_一体式多参数监测 from "@/assets/img/zhinengganzhi/app_智能感知_一体式多参数监测.jpg";
import app_智能感知_辐射环境监测仪 from "@/assets/img/zhinengganzhi/app_智能感知_辐射环境监测仪.jpg";
import app_智能感知_智能诊断 from "@/assets/img/zhinengganzhi/app_智能感知_智能诊断.jpg";
import app_智能感知_智能控制边缘网关 from "@/assets/img/zhinengganzhi/app_智能感知_智能控制边缘网关.jpg";
import app_智能感知_人员定位 from "@/assets/img/zhinengganzhi/app_智能感知_人员定位.jpg";
import app_智能感知_智能安全帽 from "@/assets/img/zhinengganzhi/app_智能感知_智能安全帽.jpg";

const windowWidth = document.documentElement.clientWidth;
export default {
  components: {
    SwiperBox,
    SlideCard,
  },
  data() {
    return {
      isShowDetail: false,
      detailIndex: 0,
      cards: [
        {
          img: zhinengganzhi,
          title: "智能感知",
          subtitle: "INTELLIGENT PERCEPTION",
        },
        {
          img: zhinengyingyong,
          title: "智慧应用",
          subtitle: "SMART APPLICATION",
        },
      ],
      zhinengganzhiRows: [
        [
          {
            img: app_智能感知_便携式水质分析仪,
            name: "便携式水质分析仪",
            list: [
              "光谱法随身测量97项水质指标",
              "专利光路补偿算法，极大降低测量偏差",
              "4G/5G数据实时回传，自动关联在线工单",
              "仪器健康状态自诊断，保障数据质量",
            ],
          },
          {
            img: app_智能感知_微试剂水质监测仪,
            name: "微试剂水质监测仪",
            list: [
              "试剂用量少、废液产生量低",
              "大幅降低运维难度及成本",
              "模块化设计，探头即插即用",
              "配备断电保护、多场景应用",
            ],
          },
          {
            img: app_智能感知_一体式多参数监测,
            name: "一体式多参数监测",
            list: [
              "模块化设计，可同时监测9项因子",
              "电化学、光学法多种类耦合",
              "无试剂使用、无二次污染",
              "即插即测、自动清洗、运维",
            ],
          },
          {
            img: app_智能感知_光谱法水质监测仪,
            name: "光谱法水质监测仪",
            list: [
              "紫外全光谱，可同时监测多参数",
              "AI实时分析，辅助降噪、补偿、特征提取",
              "参数覆盖全，支持多种监测指标",
              "无试剂使用，降低运维成本",
            ],
          },
          {
            img: app_智能感知_辐射环境监测仪,
            name: "水质光谱指纹溯源仪",
            list: [
              "建立排污企业水质光谱指纹库",
              "通过比对识别、溯源污染排放源",
              "在线监测、自动报警、留证评估",
              "高灵敏度，识别稀释排污、降雨偷排",
            ],
          },
        ],
        [
          {
            img: app_智能感知_智能控制边缘网关,
            name: "智能控制边缘网关",
            list: [
              "智能曝气、加药、滤池控制",
              "机理+AI可自学习、自适应",
              "全自动控制闭环，无人工干预",
              "数据分析、操控过程可视化",
            ],
          },
          {
            img: app_智能感知_智能诊断,
            name: "智能诊断",
            list: [
              "振动/温度/磁通量/声音监测耦合",
              "高防护等级，快速部署实施",
              "故障模型自动诊断数十种故障",
              "自动生成维修建议，指导生产",
              "深度学习，自主更新迭代",
            ],
          },
          {
            img: app_智能感知_人员定位,
            name: "人员定位",
            list: [
              "UWB毫米波定位，厘米级精度",
              "室内室外多形态，无缝过渡",
              "穿透力强，地上地下高覆盖率",
              "人员生命体征监测，守护健康",
            ],
          },
          {
            img: app_智能感知_智能安全帽,
            name: "智能安全帽",
            list: [
              "H.265高清音视频多端同步",
              "5G/4G/WiFi全网通，低延迟",
              "GPS+UWB融合精准定位",
              "SOS一键求助，跌落告警",
            ],
          },
        ],
      ],
      zhihuiyingyongRows: [
        [
          {
            img: app_智能应用_集团运营管理系统,
            name: "集团运营管理系统",
            list: [
              "灵活适配单厂/多厂/分布式厂站/泵闸等多业务场景",
              "贯穿水务集团决策层-管理层-执行层，千人千面",
              "生产运行/设备/排班/巡检/化验/报表全面管理",
              "人事物，全在线，闭环留痕，量化考核",
              "业务管理扁平化、数据资产化、决策科学化",
            ],
          },
          {
            img: app_智能应用_三维数字孪生水厂,
            name: "三维数字孪生水厂",
            list: [
              "LOD300精度BIM模型全要素还原真实水厂",
              "设计+建设期成果数字化传承，构建孪生底座",
              "嵌入机理+AI算法，在线仿真模拟，虚实互馈",
              "控制/配电回路，工艺/设备原理，所见即所得",
              "人员空间定位、隐蔽管线管理，让管理无死角",
            ],
          },
          {
            img: app_智能应用_厂网一体化运营系统,
            name: "厂网一体化运营系统",
            list: [
              "水厂、泵站、管网、防汛事件各要素精细化管理",
              "机理+大数据模型，实现水量科学调配，各厂均衡",
              "降低城市内涝风险，大幅提升防汛调度效率",
              "对接智慧城市，工单无缝流转，改善民众体验",
              "集约化运营，一体化调控，实现管理模式创新",
            ],
          },
        ],
        [
          {
            img: app_智能应用_水环境流域孪生,
            name: "水环境流域孪生",
            list: [
              "全三维场景展现水环境治理成效及大数据资产",
              "高光谱反演河道污染分布，针对性精细防治",
              "排口实时监测，水质指纹实现污染溯源",
              "感知一张网、资源一张图、协同一平台",
              "全链条/全过程/全覆盖，多方参与、共治共管",
            ],
          },
          {
            img: app_智能应用_智慧水利管理系统,
            name: "智慧水利管理系统",
            list: [
              "水库/河道/灌区/泵闸站/农饮水一平台管理",
              "多层级联动协同管理，统一监管、智能预警",
              "管理事项任务化、事项操作流程化",
              "流程处置闭环化、管理记录电子化",
              "系统操作痕迹化、运行监管指标化",
            ],
          },
          {
            img: app_智能应用_水害防御应急系统,
            name: "水害防御应急系统",
            list: [
              "智慧防汛指挥调度，资源合理分配，降低灾害影响",
              "AI驱动洪水预警预报，动态调整预案，防范于未然",
              "水利设施风险精细化管控，助力保险定损理赔",
              "实时模拟受灾情况，绘制洪水风险图，科学决策",
              "防汛物资与抢险队伍联动管理",
            ],
          },
        ],
        [
          {
            img: app_智能应用_数据采集共享底座,
            name: "数据采集共享底座",
            list: [
              "海量物联、工控设备一站接入集成，快速应用集成",
              "可视化配置，高效实现厂站数据上云",
              "支持百亿级数据的存储、查询和聚合分析",
              "支持大容量分布式部署，灵活伸缩，适配不同业务规模",
            ],
          },
          {
            img: app_智能应用_二三维空间融合引擎,
            name: "二三维空间融合引擎",
            list: [
              "高性能3D图形引擎，高精度BIM模型高速加载",
              "影视/游戏级别视觉表达，操作流畅度高",
              "大场景GIS服务集成，支持运算模型算法嵌入",
              "数据驱动实时仿真，模拟推演，赋予场景以生命",
            ],
          },
          {
            img: app_智能应用_报表引擎,
            name: "低代码工作流/报表引擎",
            list: [
              "巡检/维修/大修等流程一厂一策，拖拽式配置",
              "节点表单填报项灵活自定义，多端同步",
              "基于Excel操作习惯配置报表样式，灵活扩展",
              "在线/填报数据多源接入，周期自动生成",
              "摆脱开发依赖，业务人员即可快速调整",
            ],
          },
        ],
      ],
    };
  },
  created() {
    this.$bus.$on("back-click", this.goback);
  },
  methods: {
    showDetail(index) {
      this.isShowDetail = true;
      this.detailIndex = index;
      this.$bus.$emit("back-show", true);
    },
    goback() {
      this.isShowDetail = false;
    },
    getSwiperOption({ reverse, width } = { reverse: false, width: 200 }) {
      return {
        slidesPerView: windowWidth / width,
        spaceBetween: 30,
        loop: true,
        loopedSlides: 5,
        centeredSlides: true,
        autoplay: {
          reverseDirection: reverse,
          disableOnInteraction: false,
          waitForTransition: false,
        },
      };
    },
    onTap: function(swiper, event){
      const img = event.target;
      const activeCard = img.closest(".slidecard");
      if(activeCard.querySelector(".cardlist").classList.contains("active")) {
        activeCard.querySelector(".cardlist").classList.remove("active");
      } else {
        Array.from(document.querySelectorAll(".cardlist")).forEach((item) => {
          item.classList.remove("active");
        });
        activeCard.querySelector(".cardlist").classList.add("active");
      }
    },
    closeDetail() {
      this.isShowDetail = false;
    }
  },
};
</script>

<style lang="less" scoped>
.page3box {
  color: #fff;
  height: 100%;
  .pagedetail, .pagemain {
    padding: 50px 0;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }
}

.carditem {
  margin-top: calc(var(--vh) * 5 );
  padding: 0 20px;
  position: relative;
  .mainimg {
    width: 100%;
    height: calc(var(--vh) * 35 );
    object-fit: cover;
  }
  .cardtext {
    position: absolute;
    top: 40%;
    left: 10%;
    font-size: 20px;
    .en {
      font-size: 14px;
      margin-top: 7px;
    }
  }
}

.zhinengganzhi {
  .swiperbox {
    margin-top: 20px;
  }
}
.zhihuiyingyong {
  .swiperbox {
    &:first-child {
      margin-top: 0;
    }
    margin-top: 20px;
  }
}
</style>
