<template>
  <div class="page3box">
    <Transition name="fade">
      <div key="pagemain" v-if="!isShowDetail" class="pagemain">
        <div class="title">核心产品</div>
        <div class="content">
          <div class="box" @mousemove="leftMouseMove" @mouseleave="leftMouseLeave">
              <template v-if="leftDefaultShow">
                <img class="img-left" src="@/assets/img/pc/coreProducts_p01.png" >
                <div class="left_box_text">
                    <p class="text1">智能感知</p>
                    <p class="text2">INTELLIGENT PERCEPTION</p>
                </div>
              </template>
              <template v-else>
                <img class="img-left" src="@/assets/img/pc/coreProducts_p06.png" >
                <div class="left_info" >
                  <img class="returnBtn" @click="leftMouseLeave" src="@/assets/img/pc/coreProducts_icon_return_normal.png" >
                  <div class="left_box_text">
                      <div class="text1">智能感知</div>
                      <div class="text2">INTELLIGENT PERCEPTION</div>
                  </div>
                  <ul class="position-left">
                      <li style="margin-left: 70px;">智能安全帽</li>
                      <li style="margin-left: 60px;">智能控制边缘网关</li>
                      <li style="margin-left: 50px;">智能诊断</li>
                      <li style="margin-left: 40px;">便携式水质分析仪</li>
                      <li style="margin-left: 30px;">微试剂水质监测仪</li>
                      <li style="margin-left: 20px;">一体式多参数监测</li>
                      <li style="margin-left: 10px;">光谱法水质监测仪</li>
                      <li >辐射在线监测仪</li>
                  </ul>
                  <div class="seeDetail position-left" @click="showDetail(0)">查看详情</div>
              </div>
              </template>
              
          </div>

          <div class="box" @mousemove="rightMouseMove" @mouseleave="rightMouseLeave" style="margin-left: -15%">
              <template v-if="rightDefaultShow">
                <img class="img-right" src="@/assets/img/pc/coreProducts_p02.png" >
                <div class="right_box_text">
                    <p class="text1">智慧应用</p>
                    <p class="text2">SMART APPLICATION</p>
                </div>
              </template>
              <template v-else>
                <img class="img-right" src="@/assets/img/pc/coreProducts_p05.png" >
                <div class="right_info">
                  <img class="returnBtn" @click="rightMouseLeave" src="@/assets/img/pc/coreProducts_icon_return_normal.png" >
                  <div class="left_box_text">
                      <div class="text1">智慧应用</div>
                      <div class="text2">SMART APPLICATION</div>
                  </div>
                  <ul class="position-right">
                      <li>集团运营管理系统</li>
                      <li style="margin-left: -10px">三维数字孪生水厂</li>
                      <li style="margin-left: -20px">厂网一体化运营系统 </li>
                      <li style="margin-left: -30px">水环境流域孪生</li>
                      <li style="margin-left: -40px">智慧水利管理系统</li>
                      <li style="margin-left: -50px">水害防御应急系统</li>
                      <li style="margin-left: -60px">数据采集共享底座</li>
                      <li style="margin-left: -70px">二三维空间融合引擎</li>
                      <li style="margin-left: -80px">低代码工作流/报表引擎</li>
                  </ul>
                  <div class="seeDetail position-right-btn" @click="showDetail(1)">查看详情</div>
                </div>
              </template>

          </div>

        </div>
      </div>
      <div key="pagedetail" v-if="isShowDetail" class="pagedetail">
        <div class="back" @click="goback"></div>
        <div v-if="detailIndex === 0" class="title">智能感知</div>
        <div v-else class="title">智慧应用</div>
        
        <div v-if="detailIndex === 0" class="zhinengganzhi">
          <div class="system" v-for="(row, index) in zhinengganzhiRows" :key="index"  
            @mousemove="listMouseMove(index)" @mouseleave="listMouseLeave(index)">
            <img :src=row.img >
            <div class="detail-text">
                <p class="sys-name">{{ row.name }}</p>
                <ul class="detail-info" v-if="listTextDetail[index]">
                  <li class="info-item" v-for="(item, itemIndex) in row.list" :key="itemIndex">{{ item }}</li>          
                </ul>
            </div>
          </div>
        </div>
        <div v-if="detailIndex === 1" class="zhinengganzhi">
          <div class="system" v-for="(row, index) in zhihuiyingyongRows" :key="index"  
            @mousemove="listMouseMove(index)" @mouseleave="listMouseLeave(index)">
            <img :src=row.img >
            <div class="detail-text">
                <p class="sys-name">{{ row.name }}</p>
                <ul class="detail-info" v-if="listTextDetail[index]">
                  <li class="info-item" v-for="(item, itemIndex) in row.list" :key="itemIndex">{{ item }}</li>          
                </ul>
            </div>
          </div>
        </div>
      </div>
    </Transition>
  </div>
</template>

<script>

import 集团运营管理系统 from "@/assets/img/pc/coreProduce/集团运营管理系统.png";
import 三维数字孪生水厂 from "@/assets/img/pc/coreProduce/三维数字孪生水厂.png";
import 厂网一体化运营系统 from "@/assets/img/pc/coreProduce/厂网一体化运营系统.png";
import 水环境流域孪生 from "@/assets/img/pc/coreProduce/水环境流域孪生.png";
import 智慧水利管理系统 from "@/assets/img/pc/coreProduce/智慧水利管理系统.png";
import 水害防御应急系统 from "@/assets/img/pc/coreProduce/水害防御应急系统.png";
import 数据采集共享底座 from "@/assets/img/pc/coreProduce/数据采集共享底座.png";
import 二三维空间融合引擎 from "@/assets/img/pc/coreProduce/二三维空间融合引擎.png";
import 报表引擎 from "@/assets/img/pc/coreProduce/报表引擎.png";
import 光谱法水质监测仪 from "@/assets/img/pc/coreProduce/光谱法水质监测仪.png";
import 便携式水质分析仪 from "@/assets/img/pc/coreProduce/便携式水质分析仪.png";
import 微试剂水质监测仪 from "@/assets/img/pc/coreProduce/微试剂水质监测仪.png";
import 一体式多参数监测 from "@/assets/img/pc/coreProduce/一体式多参数监测.png";
import 辐射环境监测仪 from "@/assets/img/pc/coreProduce/辐射环境监测仪.png";
import 智能诊断 from "@/assets/img/pc/coreProduce/智能诊断.png";
import 智能控制边缘网关 from "@/assets/img/pc/coreProduce/智能控制边缘网关.png";
import 人员定位 from "@/assets/img/pc/coreProduce/人员定位.png";
import 智能安全帽 from "@/assets/img/pc/coreProduce/智能安全帽.png";

const windowWidth = document.documentElement.clientWidth;
export default {

  data() {
    return {
      leftDefaultShow: true,
      rightDefaultShow: true,
      isShowDetail: false,
      listTextDetail: [],
      detailIndex: 0,
      zhinengganzhiRows: [
        {
          img: 便携式水质分析仪,
          name: "便携式水质分析仪",
          list: [
            "光谱法随身测量97项水质指标",
            "专利光路补偿算法，极大降低测量偏差",
            "4G/5G数据实时回传，自动关联在线工单",
            "仪器健康状态自诊断，保障数据质量",
          ],
        },
        {
          img: 微试剂水质监测仪,
          name: "微试剂水质监测仪",
          list: [
            "试剂用量少、废液产生量低",
            "大幅降低运维难度及成本",
            "模块化设计，探头即插即用",
            "配备断电保护、多场景应用",
          ],
        },
        {
          img: 一体式多参数监测,
          name: "一体式多参数监测",
          list: [
            "模块化设计，可同时监测9项因子",
            "电化学、光学法多种类耦合",
            "无试剂使用、无二次污染",
            "即插即测、自动清洗、运维",
          ],
        },
        {
          img: 光谱法水质监测仪,
          name: "光谱法水质监测仪",
          list: [
            "紫外全光谱，可同时监测多参数",
            "AI实时分析，辅助降噪、补偿、特征提取",
            "参数覆盖全，支持多种监测指标",
            "无试剂使用，降低运维成本",
          ],
        },
        {
          img: 辐射环境监测仪,
          name: "水质光谱指纹溯源仪",
          list: [
            "建立排污企业水质光谱指纹库",
            "通过比对识别、溯源污染排放源",
            "在线监测、自动报警、留证评估",
            "高灵敏度，识别稀释排污、降雨偷排",
          ],
        },
        {
          img: 智能控制边缘网关,
          name: "智能控制边缘网关",
          list: [
            "智能曝气、加药、滤池控制",
            "机理+AI可自学习、自适应",
            "全自动控制闭环，无人工干预",
            "数据分析、操控过程可视化",
          ],
        },
        {
          img: 智能诊断,
          name: "智能诊断",
          list: [
            "振动/温度/磁通量/声音监测耦合",
            "高防护等级，快速部署实施",
            "故障模型自动诊断数十种故障",
            "自动生成维修建议，指导生产",
            "深度学习，自主更新迭代",
          ],
        },
        {
          img: 人员定位,
          name: "人员定位",
          list: [
            "UWB毫米波定位，厘米级精度",
            "室内室外多形态，无缝过渡",
            "穿透力强，地上地下高覆盖率",
            "人员生命体征监测，守护健康",
          ],
        },
        {
          img: 智能安全帽,
          name: "智能安全帽",
          list: [
            "H.265高清音视频多端同步",
            "5G/4G/WiFi全网通，低延迟",
            "GPS+UWB融合精准定位",
            "SOS一键求助，跌落告警",
          ],
        },
      ],
      zhihuiyingyongRows: [
        {
          img: 集团运营管理系统,
          name: "集团运营管理系统",
          list: [
            "灵活适配单厂/多厂/分布式厂站/泵闸等多业务场景",
            "贯穿水务集团决策层-管理层-执行层，千人千面",
            "生产运行/设备/排班/巡检/化验/报表全面管理",
            "人事物，全在线，闭环留痕，量化考核",
            "业务管理扁平化、数据资产化、决策科学化",
          ],
        },
        {
          img: 三维数字孪生水厂,
          name: "三维数字孪生水厂",
          list: [
            "LOD300精度BIM模型全要素还原真实水厂",
            "设计+建设期成果数字化传承，构建孪生底座",
            "嵌入机理+AI算法，在线仿真模拟，虚实互馈",
            "控制/配电回路，工艺/设备原理，所见即所得",
            "人员空间定位、隐蔽管线管理，让管理无死角",
          ],
        },
        {
          img: 厂网一体化运营系统,
          name: "厂网一体化运营系统",
          list: [
            "水厂、泵站、管网、防汛事件各要素精细化管理",
            "机理+大数据模型，实现水量科学调配，各厂均衡",
            "降低城市内涝风险，大幅提升防汛调度效率",
            "对接智慧城市，工单无缝流转，改善民众体验",
            "集约化运营，一体化调控，实现管理模式创新",
          ],
        },
        {
          img: 水环境流域孪生,
          name: "水环境流域孪生",
          list: [
            "全三维场景展现水环境治理成效及大数据资产",
            "高光谱反演河道污染分布，针对性精细防治",
            "排口实时监测，水质指纹实现污染溯源",
            "感知一张网、资源一张图、协同一平台",
            "全链条/全过程/全覆盖，多方参与、共治共管",
          ],
        },
        {
          img: 智慧水利管理系统,
          name: "智慧水利管理系统",
          list: [
            "水库/河道/灌区/泵闸站/农饮水一平台管理",
            "多层级联动协同管理，统一监管、智能预警",
            "管理事项任务化、事项操作流程化",
            "流程处置闭环化、管理记录电子化",
            "系统操作痕迹化、运行监管指标化",
          ],
        },
        {
          img: 水害防御应急系统,
          name: "水害防御应急系统",
          list: [
            "智慧防汛指挥调度，资源合理分配，降低灾害影响",
            "AI驱动洪水预警预报，动态调整预案，防范于未然",
            "水利设施风险精细化管控，助力保险定损理赔",
            "实时模拟受灾情况，绘制洪水风险图，科学决策",
            "防汛物资与抢险队伍联动管理",
          ],
        },
        {
          img: 数据采集共享底座,
          name: "数据采集共享底座",
          list: [
            "海量物联、工控设备一站接入集成，快速应用集成",
            "可视化配置，高效实现厂站数据上云",
            "支持百亿级数据的存储、查询和聚合分析",
            "支持大容量分布式部署，灵活伸缩，适配不同业务规模",
          ],
        },
        {
          img: 二三维空间融合引擎,
          name: "二三维空间融合引擎",
          list: [
            "高性能3D图形引擎，高精度BIM模型高速加载",
            "影视/游戏级别视觉表达，操作流畅度高",
            "大场景GIS服务集成，支持运算模型算法嵌入",
            "数据驱动实时仿真，模拟推演，赋予场景以生命",
          ],
        },
        {
          img: 报表引擎,
          name: "低代码工作流/报表引擎",
          list: [
            "巡检/维修/大修等流程一厂一策，拖拽式配置",
            "节点表单填报项灵活自定义，多端同步",
            "基于Excel操作习惯配置报表样式，灵活扩展",
            "在线/填报数据多源接入，周期自动生成",
            "摆脱开发依赖，业务人员即可快速调整",
          ],
        },
      ],
    };
  },
  created() {
    // this.$bus.$on("back-click", this.goback);
    this.leftDefaultShow = true;
    this.rightDefaultShow = true;
    this.$bus.$on("page-slide-change", (index) => {
      if(index !== 3 && this.isShowDetail){
        this.isShowDetail = false;
      }
    });
  },

  methods: {
    leftMouseMove(){
      this.leftDefaultShow = false;
    },
    leftMouseLeave(){
      this.leftDefaultShow = true;
    },
    rightMouseMove(){
      this.rightDefaultShow = false;
    },
    rightMouseLeave(){
      this.rightDefaultShow = true;
    },
    showDetail(index) {
      this.isShowDetail = true;
      this.detailIndex = index;
      // this.$bus.$emit("back-show", true);
    },
    listMouseMove(id){
      this.$set(this.listTextDetail,id,true);
    },
    listMouseLeave(id){      
      this.$set(this.listTextDetail,id,false);
    },
    goback() {
      this.isShowDetail = false;
    },
    getSwiperOption({ reverse, width } = { reverse: false, width: 200 }) {
      return {
        slidesPerView: windowWidth / width,
        spaceBetween: 30,
        loop: true,
        centeredSlides: true,
        autoplay: {
          reverseDirection: reverse,
          disableOnInteraction: false,
          waitForTransition: false,
        },
      };
    },
    onTap: function(){
      const cardList = Array.from(document.querySelectorAll(".slidecard"));
      cardList.forEach((el) =>
        el.addEventListener("click", () => {
          cardList.forEach((el) => {
            el.querySelector(".cardlist").classList.remove("active");
          });
          el.querySelector(".cardlist").classList.add("active");
        })
      );
    },
    closeDetail() {
      this.isShowDetail = false;
    }
  },
};
</script>

<style lang="less" scoped>
.page3box {
  height: 100%;
  color: #fff;
  padding-right: 5%;
  .pagemain {
    display: flex;
    flex-direction: column;  
    .title {
      color: white;
      font-size: 32px;
      height: 88px;
      line-height: 88px;
      font-weight: lighter;
      margin-top: 50px;
    }
    .content {
      display: flex;
      flex-direction: row;
      justify-content: center;
      .box {      
        position: relative; 
        width: 50%;
        // overflow: hidden; 
        height: 80vh;  
        .img-left {
          width: 100%;        
          height: 100%;
          object-fit: fill; 
          margin-left: 30px;       
        }
        
        .left_info {
          position: absolute;
          width: 80%;
          height: 100%;
          margin-left: 30px;
          top: 0%;
          left: 0%;
          color: white;
          font-size: 20px;
          .returnBtn {
            width: 24px;
            height: 20px;
            position: absolute;
            left: 9%;
            top: 9%;
          }
          .position-left {
            left: 36%;
          }
        }
        .img-right {
          width: 100%;        
          height: 100%;
          object-fit: fill;
          margin-left: -30px;      
        }
        .right_info {
          flex: 1;
          position: absolute;
          width: 80%;
          height: 100%;
          margin-right: 0px;
          top: 0%;
          right: 0%;
          color: white;
          font-size: 20px;
          .returnBtn {
            width: 24px;
            height: 20px;
            position: absolute;
            right: 9%;
            top: 9%;
          }
          .position-right {
            right: 36%;
          }
          .position-right-btn {
            right: 55%;
          }
        }
        .right_box_text {
          flex: 1;
          position: absolute;
          left: 24%;
          top: 75%;
          text-align: left;
          .text1 {   
            font-weight: bold;
            color: #FFFFFF;
            font-size: 20px;
            margin-bottom: 10px;
          }
          .text2 {   
            color: rgba(255,255,255,0.6);
            font-size: 16px;
            font-weight: 400;
          }
        }
       
      }
      .left_box_text {
        position: absolute;
        right: 23%;
        top: 15%;
        text-align: right;
        .text1 {   
          font-weight: bold;
          color: #FFFFFF;
          font-size: 20px;
          margin-bottom: 10px;
        }
        .text2 {   
          color: rgba(255,255,255,0.6);
          font-size: 16px;
          font-weight: 400;
        }
      }

      ul {
        position: absolute;
        // left: 26%;
        top: 35%;
        li {
          font-size: 14px;
          color: #FFFFFF;
          margin-bottom: 10px;
          list-style-type: disc;
        }
      }
      .seeDetail {
        cursor: pointer;
        position: absolute;
        // left: 25%;
        top: 80%;
        font-weight: 500;
        color: #FFFFFF;
        font-size: 14px;
        border-radius: 8px;
        border: 1px solid #076ECD;
        width: 100px;
        text-align: center;
        height: 40px;
        line-height: 40px;
      }
    }
  }


}

.pagedetail {
  height: 100%;
  display: flex;
  flex-direction: column; 
  .title {
    color: white;
    font-size: 32px;
    height: 88px;
    line-height: 102px;
    font-weight: lighter;
    margin-top: 32px;
  }
  .zhinengganzhi {
    flex:1;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 20px;
    margin-bottom: 40px;
    .system {  
      position: relative;
      background-size: cover;
      width: calc(33.3% - 20px);
      height: calc(31vh - 60px);
      margin-right: 10px;
      margin-bottom: 10px;
      
      img {
        width: 100%;
        height: 100%;
        object-fit: fill;         
      }
      .detail-text {
        position: absolute;
        bottom: 0px;
        width: 100%;
        text-align: center;
        background: linear-gradient(180deg, rgba(0, 137, 255, 0.7) 0%, #003153 100%);
        .sys-name {
          margin-top: 18px;
          margin-bottom: 18px;
        }

        .detail-info {
          display: inline-block;
          font-size: 12px;
          color: #FFFFFF;
          line-height: 20px;
          letter-spacing: 2px;
          font-weight: 400;
          text-align:left;
          margin-bottom: 20px;
          li {
            list-style: none;
          }
          .info-item::before {
            
            content: '';
            display: inline-block;
            width: 6px;
            height: 6px;
            background: #FFFFFF;
            border-radius: 50%;
            margin-right: 6px;
            margin-bottom: 1px;
          }
        }

      }
    }
    .system:nth-child(3n) {
      margin-right: 0px;
    }
  }
}

</style>
