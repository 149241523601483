<template>
  <div class="pageonebox">
    <div class="logo">
      <img src="@/assets/img/pc/logo2-1.png" alt="" />
    </div>
    
    <div class="textbox">
      <p class="text_indent">
        北京云建标科技有限公司成立于2015年，技术团队来自北控水务、威立雅、中信国安智慧城市、中国建筑标准设计研究院、阿里等，专注于水务/水利数字化建设运营。秉持“执数智之器，精水务之业”的理念，我们将AI、BIM、GIS、边云协同等技术应用于水务、水环境和水利的建设、运营、管理中，立志于成为“最懂运营管理的智慧水务科技公司”。
      </p>
      <p class="text_indent">
        借助与水务行业领先企业的深入合作，创新性地实现了将水务运营管理经验与信息化技术、自控技术、专业模型的深度融合，打造集“精确感知+无缝协同+智慧决策”为一体的多业态运营管理能力，赋能水务企业数字化转型，助力政府涉水业务的精细化监管。
      </p>
      <p class="text_indent">
        公司为国家高新技术企业和中国水协智慧水务专业委员会委员单位，企业信用等级AAA，具备CMMI3、电子与智能专业承包二级、ITSS资质，经过ISO9000、ISO20000等认证，拥有30多项软件著作权和软件产品认证，10多项智慧水务硬件发明专利。
      </p>
    </div>
    <div class="news">
      <div class="newsTitle">
        <span class="englishTitle">NEWS</span>
        <span class="chineseTitle">云建标新闻</span>
      </div>
      <SwiperBox
        class="swiperbox"
        :dataList="cardList"
        :swiperOption="swiperOption"
      >
        <template #default="data">
          <div class="cardbox" @click="showDetail(data)">
            <img class="cardimg" :src="data.picture" :alt="data.subTitle" />
            <div class="cardText">
              <div>{{ data.subTitle }}</div>
              <div>{{ data.date }}</div>
            </div>

          </div>
        </template>
      </SwiperBox>
    </div>
  </div>
</template>

<script>
import SwiperBox from "@/components/SwiperBox";
import news1Png from "@/assets/img/pc/news1.png";
import news1Jpg from "@/assets/img/pc/news1.jpg";
import news2Jpg from "@/assets/img/pc/news2.jpg";
import news3Jpg from "@/assets/img/pc/news3.jpg";
import news4Jpg from "@/assets/img/pc/news4.jpg";
import news5Png from "@/assets/img/pc/news5.png";
import news6Jpg from "@/assets/img/pc/news6.jpg";
import news7Png from "@/assets/img/pc/news7.png";
import news8Png from "@/assets/img/pc/news8.png";
import news9Png from "@/assets/img/pc/news9.png";
import news10Png from "@/assets/img/pc/news10.png";
import news11Png from "@/assets/img/pc/news11.png"
import news12Png from "@/assets/img/pc/news12.png";
import news13Png from "@/assets/img/pc/news13.png";
import news14Png from "@/assets/img/pc/news14.png";
import news15Png from "@/assets/img/pc/news15.png"

export default {
    components: {
      SwiperBox,
    },
    data() {
        return {
          swiperOption: {
            slidesPerView: 3,
            spaceBetween: 10,
            loop: true,
            autoplay: {
              disableOnInteraction: false,
              waitForTransition: false,
            },
          },
          cardList: [
             {
              subTitle:'云建标CWPilot防汛调度系统助力秦皇岛区域公司完成强降雨应对',
              picture: news15Png,
              date: "2023-08-02",
              link: "https://mp.weixin.qq.com/s/AqS1xQ1lSxj9Owo95FUp6Q",
            },
             {
              subTitle:'CWVisual助力环球影城水环境运维智慧化',
              picture: news14Png,
              date: "2023-07-18",
              link: "https://mp.weixin.qq.com/s/P8ooaF8Wog3G9iZ-ewuRdA",
            },
             {
              subTitle:'云建标参编的《城镇水务数据分类编码及主数据识别规则》正式发布',
              picture: news13Png,
              date: "2023-06-21",
              link: "https://mp.weixin.qq.com/s/I9kBtt_K-Jm6UP7xD7z5WA",
            },
             {
              subTitle:'天津创业环保南部区域公司智慧水务平台',
              picture: news12Png,
              date: "2023-06-01",
              link: "https://mp.weixin.qq.com/s/QPwWc1hLvY7Ou1WW0L9t4Q",
            },
            {
              subTitle:'云建标圆满亮相中国水协年会',
              picture: news11Png,
              date: "2023-04-19",
              link: "https://mp.weixin.qq.com/s/booD-CFsy6ss8BVQ2cwAsw",
            },
            {
              subTitle:'以“数字孪生”打造新一代未来水厂',
              picture: news1Png,
              date: "2023-02-20",
              link: "https://mp.weixin.qq.com/s/SF6H2XVAJ_nMTs0pBOo4DA",
            },
            {
              subTitle:'新起点，新征程',
              picture: news1Jpg,
              date: "2020-02-22",
              link: "https://mp.weixin.qq.com/s/S1Qtqbk6cmmk7_I-Ewn3YA",
            },
            {
              subTitle:'中国水协智慧水务专业委员会成立',
              picture: news2Jpg,
              date: "2020-03-22",
              link: "https://mp.weixin.qq.com/s/AQ-vUgdjk1ylaUoCkciNfA",
            },
            {
              subTitle:'二三维一体化平台赋能水环境智慧治理',
              picture: news3Jpg,
              date: "2020-03-23",
              link: "https://mp.weixin.qq.com/s/qEvKvbaRWEC0dc_J-92Kxw",
            },
            {
              subTitle:'“数字双胞胎”让老厂换新颜',
              picture: news4Jpg,
              date: "2020-03-24",
              link: "https://mp.weixin.qq.com/s/sS4KGFF5toaa37Fhu2_jFQ",
            },
            {
              subTitle:'江门中微子实验安装研讨会在高能所召开',
              picture: news5Png,
              date: "2020-03-29",
              link: "https://mp.weixin.qq.com/s/r5pXSqcFOgZxO4pa7saO7w",
            },
            {
              subTitle:'为什么自来水要加氯？为什么人们谈“氯”色变？',
              picture: news6Jpg,
              date: "2020-04-09",
              link: "https://mp.weixin.qq.com/s/xuu0WmTGyUsc90M48bXR1A",
            },
            {
              subTitle:'“以IT之翼”助力北控水务集团数字化转型',
              picture: news7Png,
              date: "2020-04-10",
              link: "https://mp.weixin.qq.com/s/n58wGx_QFrxLaPQLMLXmPw",
            },
            {
              subTitle:'为控制疫情，水行业在抗疫期间出台了哪些政策？',
              picture: news8Png,
              date: "2020-04-13",
              link: "https://mp.weixin.qq.com/s/_J-ebkYfP7dii0Q2KMe4bw",
            },
            {
              subTitle:'关于常规工艺水厂中细菌总数和芽孢杆菌风险防控的试运行措施',
              picture: news9Png,
              date: "2020-04-20",
              link: "https://mp.weixin.qq.com/s/FzbfwnPa3askKksf_RGd4A",
            },
            {
              subTitle:'2020年，黑臭水体治理距离“10%”的指标还有多远？',
              picture: news10Png,
              date: "2020-04-27",
              link: "https://mp.weixin.qq.com/s/Njt-atXkCqgizx9mEHcCHw",
            },
          ]
        }
    },
    methods: {
      showDetail(data) {
        window.open(data.link);
      }
    }
};
</script>

<style lang="less" scoped>
.pageonebox {
    color: #fff;
    font-size: 12px;
    padding-right: 5%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    .logo {
      margin-top: 90px;
      img {
      height: 60px;
    }

  }
  
}
.textbox {
  .text_indent {
    margin-top: 7px;
    text-indent: 2em;
    line-height: 1.5;
    font-size: 16px;
  }
}
.news {
  .newsTitle {
    color: #fff;
    margin-bottom: 15px;
    .englishTitle {
      font-size: 30px;
    }
    .chineseTitle {
      font-size: 18px;
      margin-left: 10px;     
    }
  }
  .cardbox { 
    font-size: 16px;
    line-height: 25px;
    color: #fff;
    .cardimg {
      // width: 450px;
      // object-fit: cover;
      width: 100%;
      height: auto;
    }
    .cardText {
      width: 100%;
      position: absolute;  
      bottom: 10px;  
      left: 0px;
      opacity: 0.8;
      font-size: 16px;
      font-weight: normal;
      line-height: 25px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      div {
        padding: 0px 0px 0px 20px;
        text-overflow:ellipsis; 
        overflow: hidden;
      }
    }
  }
}

</style>
